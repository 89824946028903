import { CREDISTORY } from './credistory';

export const REGISTRATION = {
  PLATFORM_BANKS: ['046577756', '046015762', '044525823'],
  CREDIT_CARD_BANKS: ['044525974', '045004763', '043304728'],
  PARTNERS: ['avtocod', 'credistory', 'scoringburo'],
  FORM_PARAMS: {
    TERM: [1, 6, 12, 24, 36, 48, 60, 84],
    AMOUNT: [30000, 7500000],
  },
  CREDIT: {
    AMOUNT: 1200000,
    TERM: 24,
    RATE: 9.9,
  },
  CREDIT_NON_PLATFORM: {
    AMOUNT: 1200000,
    TERM: 24,
    RATE: 9.9,
  },
  CREDIT_CARD: {
    LIMIT: 10000000,
    PERIOD: 1095,
  },
  AGGREGATORS: {
    AVTOCOD: {
      ID: 'avtocod.ru',
    },
    CREDISTORY: {
      ID: 'credistory',
      FORM_PARAMS: {
        TERM: [6, 12, 24, 36, 48, 60],
        AMOUNT: [30000, 5000000],
      },
      CREDIT: {
        AMOUNT: 200000,
        TERM: 24,
        RATE: 4.5,
      },
      CREDIT_NON_PLATFORM: {
        AMOUNT: 200000,
        TERM: 24,
        RATE: 6.9,
      },
      CREDIT_CARD: {
        CREDIT_LIMIT: 10000000,
        PERIOD: 1095,
      },
    },
    SCORINGBURO: {
      ID: 'scoringburo',
      FORM_PARAMS: {
        TERM: [6, 12, 24, 36, 48, 60],
        AMOUNT: [30000, 5000000],
      },
      CREDIT: {
        AMOUNT: 200000,
        TERM: 24,
        RATE: 4.5,
      },
      CREDIT_NON_PLATFORM: {
        AMOUNT: 200000,
        TERM: 24,
        RATE: 6.9,
      },
      CREDIT_CARD: {
        CREDIT_LIMIT: 10000000,
        PERIOD: 1095,
      },
    },
  },
  PAGES: {
    CREDISTORY,
  },
};
