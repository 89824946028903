import { templatify } from './templatify';

const bic = (strBic) => strBic?.padStart(9, '0');

const templateUrl = (
  entity,
  { URL_TEMPLATE, CUSTOM_URL_TEMPLATE = {}, ...config },
) => {
  return templatify(
    CUSTOM_URL_TEMPLATE[bic(entity?.bank_bic)] || URL_TEMPLATE || '',
    {
      entity,
      ...config,
    },
  );
};

export function mapLandingProducts(
  products,
  {
    URL_TARGET,
    URL_TEMPLATE,
    URL_PARAMS,
    CUSTOM_URL_TEMPLATE = {},
    MARKETING = {},
    ...config
  },
) {
  const logo = (bankBic, bankLogo) => {
    const core = `${config.S3_ASSETS}/credits/images/banks/logo-horizontal-bank-`;

    if (bankBic) {
      return `${core}${bic(bankBic)}.svg`;
    }

    return `${core}${bankLogo?.replace(/.*(\d{9})\..*$/, '$1')}.svg`;
  };

  return products.map((item) => ({
    id: item.id,
    name: item.title,
    marketing: MARKETING[bic(item.bank_bic)],
    link: (() => {
      console.log(MARKETING, bic(item.bank_bic));
      const url = new URL(item.online_application.url);
      const origin = url.origin + url.pathname;
      const params = {};

      url.searchParams.forEach((value, key) => {
        params[key] = value;
      });

      return templateUrl(
        {
          productId: item.product_id || item.nonplatform_product_id || '',
          ...params,
          ...item,
          ...URL_PARAMS,
          origin,
        },
        { ...config, CUSTOM_URL_TEMPLATE, URL_TEMPLATE },
      );
    })(),
    linkTarget: URL_TARGET,
    bankInfo: {
      bic: item.bank_bic,
      name: item.bank_title,
      logo: logo(item.bank_bic, item.logo),
    },
    rate: item.interest_rate?.from,
    amount: item.amount?.to || item.credit_limit?.to,
    term: item.period?.to
      ? {
        type: 'MONTH',
        value: item.period.to,
      }
      : {
        type: 'DAY',
        value: item.grace_period,
      },
  }));
}

export const mapLandingProductsMFOLink = (
  item,
  {
    URL_PARAMS,
    CUSTOM_URL_TEMPLATE,
    URL_TEMPLATE,
    ...config
  },
) => {
  const params = {};

  if (item.online_application?.url) {
    const url = new URL(item?.online_application.url);
    const origin = url.origin + url.pathname;

    params.origin = origin;
    url.searchParams?.forEach((value, key) => {
      params[key] = value;
    });
  }

  const linkRaw = templateUrl(
    {
      productId: item.product_id || item.nonplatform_product_id || '',
      ...params,
      ...item,
      ...URL_PARAMS,
    },
    { ...config, CUSTOM_URL_TEMPLATE, URL_TEMPLATE },
  );

  return linkRaw.replace(/\{\{.*?\}\}/gim, '');
};

export function mapLandingProductsMFO(
  products,
  {
    URL_TARGET,
    URL_TEMPLATE,
    URL_PARAMS,
    CUSTOM_URL_TEMPLATE = {},
    ...config
  },
) {
  const logo = (item) => `${config.S3_ASSETS}/credits/images/mfo/logo-horizontal-mfo-${item.mfo_url}.svg`;

  return products.map((item) => ({
    id: item.id,
    name: item.title,
    link: mapLandingProductsMFOLink(item, {
      CUSTOM_URL_TEMPLATE,
      URL_TEMPLATE,
      URL_PARAMS,
      ...config,
    }),
    linkTarget: URL_TARGET,
    bankInfo: {
      key: item.mfo_url,
      name: item.mfo_title,
      logo: logo(item),
    },
    rate: item.interest_rate?.from,
    amount: item.amount?.to || item.credit_limit?.to,
    term: {
      type: 'DAY',
      value: item.period.to,
    },
  }));
}
