export const getLoginUrl = ({
  host,
  amount,
  aggregatorId,
  term,
  creditLimit,
  period,
  productId,
  isSimple,
} = {}) => {
  const params = {
    amount,
    productId,
    aggregatorId,
    creditLimit,
    period,
    termValue: term?.value,
    termType: term?.type,
  };
  const urlParams = new URLSearchParams();

  Object.entries(params)
    .filter(([, value]) => value !== undefined)
    .forEach(([key, value]) => urlParams.append(key, value));

  const application = encodeURIComponent(`/credits/new?${urlParams.toString()}`);
  const baseParams = [
    'silent',
    isSimple && 'isSimple',
    `returnTo=${application}`,
  ].filter((x) => x).join('&');
  return `${host}/registration?${baseParams}`;
};
