export const LAMODA = {
  CORE: {
    PAGE_GTM_CATEGORY: 'credits_lamoda_landing',
    PAGE_GTM_CLICK: 'credits_lamoda_landing_click',
  },
  META: {
    TITLE:
      'Оформите  первый кредит на Финуслугах и получите сертификат Lamoda на 2000 ₽',
    CANONICAL: '{{PUBLIC_HOST}}/potrebitelskie_kredity/landing/lamoda',
    ADDITIONAL_LINKS: [],
    ADDITIONAL_METAS: [
      {
        property: 'og:site-name',
        content:
          'Финуслуги | Оформите  первый кредит на Финуслугах и получите сертификат Lamoda на 2000 ₽',
      },
      {
        property: 'og:description',
        content:
          'Финуслуги - маркетплейс финансовых услуг от Мосбиржи, платформа №1 в реестре ЦБ РФ.',
      },
      { property: 'og:type', content: 'website' },
    ],
  },
  HERO: {
    ORDER: 1,
    IMAGE_SRC: '/potrebitelskie_kredity/lamoda/lamoda-hero__image.png',
    IMAGE_ALT: 'девушка в платье',
    BUTTON: {
      URL: null,
      TARGET: '_self',
      NAVIGATE: '#products',
    },
    LINK: {
      URL: 'https://assets.finuslugi.ru/mp-assets/docs/bonus-program-rules/credit_partner_lamoda/credit_partner_lamoda.pdf?_gl=1*16pipkg*_ga*OTg5NzIyNDMwLjE2NDY5MTAxNjU.*_ga_Z0GRHZ4BLP*MTY4NTk3NjAzNC44MS4xLjE2ODU5NzgyMTUuMjkuMC4w&_ga=2.218979098.26327008.1685976034-989722430.1646910165',
      TARGET: '_blank',
    },
    TEXTS: {
      title: 'lamoda.hero.title',
      caption: 'lamoda.hero.caption',
      button: 'lamoda.hero.button',
      link: 'lamoda.hero.link',
    },
    GTM: {
      clickLink: 'lamoda_hero_link',
      clickButton: 'lamoda_hero_button',
    },
  },
  PRODUCTS: {
    ORDER: 2,
    TABS: [
      {
        id: 1,
        name: 'Онлайн на Финуслугах',
        shortName: 'Онлайн',
        value: 'online',
        configKey: 'PLATFORM',
      },
      {
        id: 2,
        name: 'В отделении банка',
        shortName: 'В отделении',
        value: 'offline',
        configKey: 'NON_PLATFORM',
      },
    ],
    PLATFORM: {
      ID: 29,
      COUNT: 4,
      URL_TEMPLATE:
        'https://lk.finuslugi.ru/credits/new?productId={{entity.productId}}&skipName&silent&registrationMode=gosuslugi&utm_source={{entity.utm_source}}&utm_medium={{entity.utm_medium}}&utm_campaign={{entity.utm_campaign}}',
      URL_TARGET: '_blank',
      URL_PARAMS: {
        utm_source: 'lamoda',
        utm_medium: 'affiliate',
        utm_campaign: 'finuslugi_aff_lamoda_platform_kredity_landing_apr-11',
      },
      TEXTS: {
        itemButton: 'landing.products.item.button',
      },
      GTM: {
        clickButton: 'lamoda_products_item',
      },
    },
    NON_PLATFORM: {
      ID: 30,
      CREDIT_CARDS_ID: 31,
      COUNT: 4,
      URL_TEMPLATE:
        'https://lk.finuslugi.ru/credits/new?productId={{entity.productId}}&skipName&silent&registrationMode=gosuslugi&utm_source={{entity.utm_source}}&utm_medium={{entity.utm_medium}}&utm_campaign={{entity.utm_campaign}}',
      URL_TARGET: '_blank',
      URL_PARAMS: {
        utm_source: 'lamoda',
        utm_medium: 'affiliate',
        utm_campaign: 'finuslugi_aff_lamoda_neplatform_kredity_apr-11',
      },
      TEXTS: {
        cardsTitle: 'magnet2.products.cards-title',
        itemButton: 'landing.products.item.button-nonplatform',
      },
      GTM: {
        clickButton: 'lamoda_products_item',
      },
    },
    TEXTS: {
      title: 'magnet2.products.title',
    },
    GTM: {
      tabChange: 'lamoda_products_tab',
    },
  },
  STEPS: {
    ORDER: 3,
    PLATFORM: [
      {
        id: 1,
        // image: '/potrebitelskie_kredity/magnet2/magnet2-step1.png',
        // title: 'magnet2.steps.item.1.title',
        text: 'lamoda.steps.item.platform-1.text',
        tooltip: 'lamoda.steps.tooltip',
      },
      {
        id: 2,
        // image: '/potrebitelskie_kredity/magnet2/magnet2-step2.png',
        // title: 'magnet2.steps.item.2.title',
        text: 'lamoda.steps.item.platform-2.text',
      },
      {
        id: 3,
        // image: '/potrebitelskie_kredity/magnet2/magnet2-step3.png',
        // title: 'magnet2.steps.item.3.title',
        text: 'lamoda.steps.item.platform-3.text',
      },
      {
        id: 4,
        text: 'lamoda.steps.item.platform-4.text',
      },
    ],
    NON_PLATFORM: [
      {
        id: 1,
        // image: "/potrebitelskie_kredity/magnet2/magnet2-step1.png",
        // title: "magnet2.steps.item.1.title",
        text: 'lamoda.steps.item.neplatform-1.text',
        tooltip: 'lamoda.steps.tooltip',
      },
      {
        id: 2,
        // image: "/potrebitelskie_kredity/magnet2/magnet2-step4.png",
        // title: "magnet2.steps.item.4.title",
        text: 'lamoda.steps.item.neplatform-2.text',
      },
      {
        id: 3,
        // image: '/potrebitelskie_kredity/magnet2/magnet2-step3.png',
        // title: 'magnet2.steps.item.3.title',
        text: 'lamoda.steps.item.neplatform-3.text',
      },
      {
        id: 4,
        text: 'lamoda.steps.item.neplatform-4.text',
      },
      {
        id: 5,
        text: 'lamoda.steps.item.neplatform-5.text',
      },
    ],
    BUTTON: {
      URL: null,
      TARGET: '_self',
      NAVIGATE: '#products',
    },
    TEXTS: {
      title: 'lamoda.steps.title',
      button: 'lamoda.steps.button',
      badge: 'lamoda.steps.badge',
    },
    GTM: {
      clickButton: 'lamoda_steps_button',
    },
  },
  ADVANTAGES: {
    ORDER: 4,
    ITEMS: [
      {
        id: 1,
        image: '/potrebitelskie_kredity/magnet2/magnet2-advantages__star.svg',
        title: 'lamoda.advantages.item.1.title',
        text: 'lamoda.advantages.item.1.text',
        color: 'rgba(251, 150, 57, 0.24)',
      },
      {
        id: 2,
        image: '/potrebitelskie_kredity/magnet2/magnet2-advantages__shield.svg',
        title: 'lamoda.advantages.item.2.title',
        text: 'lamoda.advantages.item.2.text',
        color: 'rgba(99, 177, 243, 0.24)',
      },
      {
        id: 3,
        image: '/potrebitelskie_kredity/magnet2/magnet2-advantages__hand.svg',
        title: 'lamoda.advantages.item.3.title',
        text: 'lamoda.advantages.item.3.text',
        color: 'rgba(73, 197, 93, 0.24)',
      },
    ],
    TEXTS: {
      title: 'lamoda.advantages.title',
    },
  },
  PARTNERS: {
    ORDER: 5,
    ITEMS: [
      {
        id: 1,
        name: 'Газпромбанк',
        bic: '044525823',
      },
      {
        id: 2,
        name: 'Тинькофф',
        bic: '044525974',
      },
      {
        id: 3,
        name: 'Банк Центр-Инвест',
        bic: '046015762',
      },
      {
        id: 5,
        name: 'СинараБанк',
        bic: '046577756',
      },
      {
        id: 6,
        name: 'Совкомбанк',
        bic: '043469743',
      },
      {
        id: 8,
        name: 'Металлинвестбанк',
        bic: '044525176',
      },
    ],
    TEXTS: {
      title: 'magnet2.partners.title',
    },
  },
  FAQ: {
    ORDER: 7,
    ITEMS: [
      {
        id: 1,
        question: 'Какой бонус от Lamoda я получу?',
        answer:
          'Бонус в виде электронного подарочного сертификата Lamoda направит на адрес электронной почты,  указанный при оформлении кредитной завки (электронная почта к которой привязан профиль Госуслуг).',
      },
      {
        id: 2,
        question:
          'Бонус можно получить за любой кредит на Финуслугах или только за первый?',
        answer:
          'Только за первый. Если у вас есть или был кредит, который вы оформляли на Финуслугах, подарочный сертификат не будет предостаавлен.',
      },
      {
        id: 3,
        question: 'Кредит важно оформить онлайн?',
        answer:
          'Не обязательно. Вы можете оформить кредит полностью онлайн или заполнить заявку на Финуслугах, а кредит получить уже в отделении банка. Важно оформить кредитную заявку именно на сайте или в приложении Финуслуг — тогда подарочный сертификат будет предоставлен.',
      },
    ],
    TEXTS: {
      title: 'magnet.faq.title',
    },
    GTM: {
      linkClick: 'lamoda_faq_link',
    },
  },
  FOOTER: {
    TEXTS: {
      caption: 'lamoda.footer.caption',
    },
    GTM: {
      clickPhone: 'lamoda_footer_phone',
    },
  },
};
