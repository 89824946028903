export const INTERFAX = {
  CORE: {
    PAGE_GTM_CATEGORY: 'credits_interfax_landing',
    PAGE_GTM_CLICK: 'credits_interfax_landing_click',
    LOGO: '/potrebitelskie_kredity/interfax/interfax-header__image.svg',
    MARKETING: {
      '046577756': 'Реклама ПАО Банк Синара',
      '044525823': 'Реклама АО Банк ГПБ',
      '044525176': 'Реклама ПАО АКБ «МЕТАЛЛИНВЕСТБАНК»',
      '046015762': 'Реклама ПАО КБ «Центр-инвест»',
      '044525187': 'Реклама ПАО Банк ВТБ',
      '044525974': 'Реклама АО «Тинькофф Банк»',
      '046577795': 'Реклама ПАО КБ «УБРиР»',
      '044525593': 'Реклама АО «Альфа-Банк»',
      '044525214': 'Реклама АО «Почта Банк»',
      '044525732': 'Реклама АО КБ «Пойдём!»',
      '045004763': 'Реклама ПАО «Совкомбанк»',
    },
  },
  META: {
    TITLE: 'Кредит с выгодной ставкой',
    CANONICAL: '{{PUBLIC_HOST}}/potrebitelskie_kredity/landing/interfax',
    ADDITIONAL_LINKS: [],
    ADDITIONAL_METAS: [
      {
        property: 'og:site-name',
        content: 'Финуслуги | Кредит с выгодной ставкой',
      },
      {
        property: 'og:description',
        content:
          'Финуслуги - маркетплейс финансовых услуг от Мосбиржи, платформа №1 в реестре ЦБ РФ.',
      },
      { property: 'og:type', content: 'website' },
    ],
  },
  HERO: {
    ORDER: 1,
    IMAGE_SRC: '/potrebitelskie_kredity/interfax/interfax-hero__image.png',
    IMAGE_ALT: 'Кредит с выгодной ставкой',
    LINK: {
      URL: '#',
      TARGET: '_blank',
    },
    BUTTON: {
      URL: null,
      THEME: 'accent',
      TARGET: '_blank',
      NAVIGATE: '#products',
    },
    TEXTS: {
      title: 'interfax.hero.title',
      caption: 'interfax.hero.caption',
      button: 'interfax.hero.button',
      link: 'interfax.hero.link',
    },
    GTM: {
      clickLink: 'interfax_hero_link',
      clickButton: 'interfax_hero_button',
    },
  },
  ABOUT: {
    ORDER: 2,
    TEXTS: {
      title: 'interfax.about.title',
      caption: 'interfax.about.caption',
    },
    ITEMS: [
      {
        id: 1,
        image: '/potrebitelskie_kredity/interfax/interfax-about__item-1.svg',
        title: 'Надёжность Mосковской биржи',
      },
      {
        id: 2,
        image: '/potrebitelskie_kredity/interfax/interfax-about__item-2.svg',
        title: 'Без скрытых условий и звездочек',
      },
      {
        id: 3,
        image: '/potrebitelskie_kredity/interfax/interfax-about__item-3.svg',
        title: 'Легко управлять вкладами онлайн',
      },
    ],
  },
  PLATFORM_PRODUCTS: {
    ORDER: 2,
    ID: 'products',
    ALIGN: 'left',
    PLATFORM: {
      ID: 33,
      URL_PARAMS: {
        utm_source: 'interfax',
        utm_medium: 'affiliate',
        utm_campaign: 'finuslugi_aff_interfax_platform_kredity_june-14',
      },
    },
    TEXTS: {
      platformTitle: 'interfax.products.platform.title',
      platformCaption: 'interfax.products.platform.caption',
    },
    GTM: {
      clickButton: 'interfax_platform_products_item',
    },
  },
  PROCEED: {
    ORDER: 3,
    TEXTS: {
      title: 'interfax.proceed.title',
    },
    ITEMS: [
      {
        id: 1,
        text: 'Зарегистрируйтесь на Финуслугах и заполните кредитную заявку',
      },
      {
        id: 2,
        text: 'Отправьте предварительную заявку в несколько банков, чтобы выбрать предложение',
      },
      {
        id: 3,
        text: 'Дождитесь финального одобрения от банка и получите деньги',
      },
    ],
  },
  NON_PLATFORM_PRODUCTS: {
    ORDER: 4,
    ID: 'nonplatform_products',
    ALIGN: 'left',
    MAXROW: 4,
    NON_PLATFORM: {
      ID: 34,
      URL_TEMPLATE:
        'https://lk.finuslugi.ru/credits/new?productId={{entity.productId}}&skipName&silent&isSimple&registrationMode=phone&utm_source={{entity.utm_source}}&utm_medium={{entity.utm_medium}}&utm_campaign={{entity.utm_campaign}}',
      URL_PARAMS: {
        utm_source: 'interfax',
        utm_medium: 'affiliate',
        utm_campaign: 'finuslugi_aff_interfax_neplatform_kredity_june-14',
      },
      CUSTOM_URL_TEMPLATE: {
        '044525593':
          'http://54081f.binomlink.com/click.php?key=ace3d7y4w6ai9vsf2szx&utm_source={{entity.utm_source}}&utm_medium={{entity.utm_medium}}&utm_campaign={{entity.utm_campaign}}',
        '044525214':
          'http://54081f.binomlink.com/click.php?key=ay20i5eh23q8uwovlgdw&utm_source={{entity.utm_source}}&utm_medium={{entity.utm_medium}}&utm_campaign={{entity.utm_campaign}}',
      },
    },
    TEXTS: {
      nonPlatformTitle: 'interfax.products.nonplatform.title',
    },
    GTM: {
      clickButton: 'interfax_neplatform_products_item',
    },
  },
  FAQ: {
    ORDER: 6,
    THEME: 'white',
    ITEMS: [
      {
        id: 1,
        question:
          'Заявку на кредит можно оформить на Финуслугах онлайн, а оригиналы документов нужно предоставлять банку?',
        answer:
          'Во время оформления онлайн кредита нужно будет предоставить только сканы (фото) требуемых документов. Если выбран кредит с оформлением в банке, то и документы, при необходимости, нужно будет предоставлять в офис банка.',
      },
      {
        id: 2,
        question:
          'Как происходит подписание кредитного договора на Финуслугах?',
        answer:
          'При оформлении онлайн кредита вам не нужно идти в банк, ждать распечатку документов и подписывать их вручную. Подписание с вашей стороны происходит простой электронной подписью, со стороны банка — усиленной неквалифицированной подписью. Обе подписи полностью законны. Документы, подписанные электронными подписями, приравниваются к оригиналам печатных договоров. Если вы выберете кредит с оформлением в банке, подписать кредитные документы нужно будет в офисе банка.',
      },
      {
        id: 3,
        question:
          'На Финуслугах можно взять несколько кредитов или только один?',
        answer:
          'Вы можете отправить одну заявку в несколько банков, а затем выбрать, в каком возьмете кредит. Для того чтобы получить следующий кредит, нужно будет заполнить заявку еще раз. Активная кредитная заявка может быть только одна — нельзя подать новую, пока банки не ответили вам по предыдущей.',
      },
      {
        id: 4,
        question: 'Как я узнаю, что банк одобрил мне кредит?',
        answer:
          'Мы пришлем вам смс и емейл. Вся информация о вашем кредите также будет в Личном кабинете на Финуслугах.',
      },
      {
        id: 5,
        question: 'Как я получу деньги?',
        answer: `Вы можете выбрать, куда придут деньги, перед подписанием кредитного договора:  
    * на счет в том же банке, где оформляете кредит;
    * на Кошелек — личный счет на Финуслугах;
    * на ваш счет в другом банке.  
    Если выберете кредит с оформлением в банке, способ получения денег — на счет, на карту или в кассе — можно будет определить в офисе банка.`,
      },
      {
        id: 6,
        question: 'Кредит можно взять только онлайн?',
        answer:
          'Не обязательно. Вы можете оформить кредит полностью на Финуслугах или в выбранном офисе банка.',
      },
    ],
    GTM: {
      linkClick: 'interfax_faq_link',
    },
  },
  FOOTER: {
    RUSBONDS: '/potrebitelskie_kredity/interfax/interfax-footer__rusbonds.svg',
    MOEX: '/potrebitelskie_kredity/interfax/interfax-footer__moex.svg',
    INTERFAX: '/potrebitelskie_kredity/interfax/interfax-footer__interfax.svg',
    LINKS: {
      rusbonds: 'https://rusbonds.ru/',
      moex: 'https://www.moex.com/',
      interfax: 'https://group.interfax.ru/',
    },
    TEXTS: {
      caption: 'interfax.footer.caption.markdown',
      link: 'interfax.footer.link.markdown',
    },
    GTM: {
      clickPhone: 'interfax_footer_phone',
    },
  },
};
