export const HEADER = {
  LINKS: [
    {
      NAME: 'Кредиты',
      HREF: '{{SHOWCASE_ROOT_URL}}/potrebitelskie_kredity',
      GTM: '/potrebitelskie_kredity',
    },
    {
      NAME: 'Микрозаймы',
      HREF: '{{SHOWCASE_ROOT_URL}}/mikrozajmy',
      GTM: '/mikrozajmy',
    },
    {
      NAME: 'О Финуслугах',
      HREF: '{{SHOWCASE_ROOT_URL}}/about',
      GTM: '/about',
    },
  ],
};
