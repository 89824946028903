//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SupportPhoneLink',
  props: {
    phone: {
      type: Object,
      default: () => ({
        tel: '',
        number: '',
      }),
    },
    id: {
      type: String,
      default: null,
    },
  },
};
