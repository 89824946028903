export const LANDING = {
  CORE: {
    PAGE_GTM_CATEGORY: 'credits_landing',
    PAGE_GTM_CLICK: 'credits_landing_click',
    PAGE_GTM: 'show_page',
  },
  HERO: {
    // Посмотреть условия акции
    LINK: null,
    LINK_TARGET: '_blank',
    // Кнопка
    BUTTON: null,
    BUTTON_THEME: 'primary',
    BUTTON_TARGET: '_blank',
    TEXTS: {
      title: 'landing.hero.title',
      subtitle: 'landing.hero.subtitle',
      text: 'landing.hero.text',
      link: 'landing.hero.link',
      button: 'landing.hero.button',
    },
    GTM: {
      clickLink: 'landing_hero_link',
      clickButton: 'landing_hero_button',
    },
  },
  PRODUCTS: {
    COUNT: 10,
    PLATFORM: {
      URL_TEMPLATE:
        'https://lk.finuslugi.ru/credits/new?productId={{entity.productId}}&skipName&silent&registrationMode=gosuslugi&utm_source={{entity.utm_source}}&utm_medium={{entity.utm_medium}}&utm_campaign={{entity.utm_campaign}}',
      URL_PARAMS: {},
      URL_TARGET: '_blank',
    },
    NON_PLATFORM: {
      URL_TEMPLATE:
        'https://lk.finuslugi.ru/credits/new?productId={{entity.productId}}&skipName&silent&registrationMode=gosuslugi&utm_source={{entity.utm_source}}&utm_medium={{entity.utm_medium}}&utm_campaign={{entity.utm_campaign}}',
      URL_PARAMS: {},
      URL_TARGET: '_blank',
    },
    TEXTS: {
      platformTitle: 'landing.products.platform.title',
      nonPlatformTitle: 'landing.products.non-platform.title',
      itemButton: 'landing.products.item.button',
      itemButtonNonPlatform: 'landing.products.item.button-nonplatform',
    },
    GTM: {
      clickButton: 'landing_products_item',
    },
  },
  PROCEED: {
    ITEMS: [],
    TEXTS: {
      title: 'landing.proceed.title',
      prefix: 'landing.proceed.prefix',
    },
  },
  ABOUT: {
    // Оформить кредит
    BUTTON: null,
    BUTTON_TARGET: null,
    IMAGE: '/potrebitelskie_kredity/common/landing-about.png',
    ALT: 'руки с телефонами',
    TEXTS: {
      title: 'landing.about.title',
      info: 'landing.about.info',
      button: 'landing.about.button',
    },
    GTM: {
      clickButton: 'landing_about_button',
    },
  },
  USEFULL: {
    ITEMS: [],
    SUBSCRIBE_LINK: '{{DASHAMAIL_URL}}/subscribe.php?list_id=56713Embedded',
    TEXTS: {
      title: 'landing.usefull.title',
      subtitle: 'landing.usefull.subtitle',
      alt: 'landing.usefull.alt',
      formTitle: 'landing.usefull.form.title',
      formSubscribed: 'landing.usefull.form.subscribed',
      formProcessing: 'landing.usefull.form.processing',
      formPlaceholder: 'landing.usefull.form.email.placeholder',
      formButton: 'landing.usefull.form.button',
      formCaption: 'landing.usefull.form.caption',
    },
    GTM: {
      linkClick: 'landing_usefull_link',
      clickButton: 'landing_usefull_button',
    },
  },
  SUPPORT: {
    PHONES: [
      { id: 1, phone: '8 800 505-32-32', value: 'tel:88005053232' },
      { id: 2, phone: '+7 495 145-32-32', value: 'tel:+74951453232' },
    ],
    SOCIALS: [
      {
        id: 1,
        icon: '/potrebitelskie_kredity/common/landing-support_moex-icon.svg',
        name: 'Чат',
        link: null,
      },
      {
        id: 2,
        icon: '/potrebitelskie_kredity/common/landing-support_telegram-icon.svg',
        name: 'Телеграм',
        link: 'https://t.me/FinuslugiBot',
      },
      {
        id: 3,
        icon: '/potrebitelskie_kredity/common/landing-support_whatsapp-icon.svg',
        name: 'WhatsApp',
        link: 'https://wa.me/74951453232',
      },
    ],
    TEXTS: {
      title: 'landing.support.title',
      subtitle: 'landing.support.subtitle',
      alt: 'landing.support.alt',
      phoneTitle: 'landing.support.phone-title',
      socialsTitle: 'landing.support.socials-title',
    },
    GTM: {
      phoneClick: 'landing_support_phone',
      socialClick: 'landing_support_social',
    },
  },
  FAQ: {
    ITEMS: [],
    LINK: 'https://finuslugi.ru/help',
    LINK_TARGET: '_blank',
    TEXTS: {
      title: 'landing.faq.title',
      link: 'landing.faq.link',
    },
    GTM: {
      linkClick: 'landing_faq_link',
    },
  },
  FOOTER: {
    PHONES: [
      {
        id: 1,
        phone: '8 800 505-32-32',
        value: 'tel:88005053232',
        info: 'Россия',
      },
      {
        id: 2,
        phone: '+7 495 145-32-32',
        value: 'tel:+74951453232',
        info: 'Для Москвы и МО',
      },
    ],
    TEXTS: {
      caption: 'landing.footer.caption.markdown',
    },
    GTM: {
      clickPhone: 'landing_footer_phone',
    },
  },
};
