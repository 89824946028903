//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TheFooter from '@/components/Layout/TheFooter';

export default {
  name: 'CommonLayout',
  components: { TheFooter },
  data: () => ({
    isMounted: false,
  }),
  computed: {
    creditDashboardLink() {
      return `${this.$config.SHOWCASE_ROOT_URL}/potrebitelskie_kredity`;
    },
    partners() {
      return this.$siteConfig.REGISTRATION.PARTNERS;
    },
    aggregatorSlug() {
      if (!this.$route.query.aggregator_id) {
        return 'avtocod';
      }

      return this.partners.find((slug) => this.$route.query.aggregator_id.includes(slug));
    },
    aggregator() {
      const { aggregator_id: aggId = 'avtocod' } = this.$route.query;
      const aggregatorSlug = this.partners.find((slug) => aggId.includes(slug));
      const width = window?.screen.width || window?.innerWidth || 1024;

      return {
        url: width > 720
          ? `/potrebitelskie_kredity/promo/icons/${aggregatorSlug}-horizontal.svg`
          : `/potrebitelskie_kredity/promo/icons/${aggregatorSlug}-square.svg`,
        title: 'Партнер сервиса',
      };
    },
  },
  mounted() {
    this.isMounted = true;
    this.$store.dispatch('dictionary/fetch');
  },
};
