export const PETROVICH = {
  CORE: {
    PAGE_GTM_CATEGORY: 'credits_petrovich_landing',
    PAGE_GTM_CLICK: 'credits_petrovich_landing_click',
    DATA_SOURCE: 'MFO_BONUS',
  },
  META: {
    TITLE: 'Оформите кредит на Финуслугах, а мы дадим вам 3000 ₽',
    CANONICAL: '{{PUBLIC_HOST}}/potrebitelskie_kredity/landing/petrovich',
    ADDITIONAL_LINKS: [],
    ADDITIONAL_METAS: [
      {
        property: 'og:site-name',
        content:
          'Финуслуги | Оформите кредит на Финуслугах, а мы дадим вам 3000 ₽',
      },
      {
        property: 'og:description',
        content:
          'Финуслуги - маркетплейс финансовых услуг от Мосбиржи, платформа №1 в реестре ЦБ РФ.',
      },
      { property: 'og:type', content: 'website' },
    ],
  },
  HERO: {
    ORDER: 1,
    IMAGE_SRC: '/potrebitelskie_kredity/petrovich/petrovich-hero__image.png',
    IMAGE_ALT: 'Промокод: FINIK*******',
    BUTTON: {
      URL: null,
      TARGET: '_self',
      NAVIGATE: '#products',
    },
    LINK: {
      URL: 'https://assets.finuslugi.ru/mp-assets/docs/bonus-program-rules/aktsiya-petrovich/pravila-aktsii.pdf',
      TARGET: '_blank',
    },
    TEXTS: {
      title: 'petrovich.hero.title',
      subtitle: 'petrovich.hero.subtitle',
      caption: 'petrovich.hero.caption',
      button: 'petrovich.hero.button',
      link: 'petrovich.hero.link',
      dates: 'petrovich.hero.dates',
    },
    GTM: {
      clickLink: 'petrovich_hero_link',
      clickButton: 'petrovich_hero_button',
    },
  },
  ADVANTAGES: {
    ORDER: 2,
    ITEMS: [
      {
        id: 1,
        image: '/potrebitelskie_kredity/magnet2/magnet2-advantages__star.svg',
        title: 'petrovich.advantages.item.1.title',
        text: 'petrovich.advantages.item.1.text',
        color: 'rgba(251, 150, 57, 0.24)',
      },
      {
        id: 2,
        image: '/potrebitelskie_kredity/magnet2/magnet2-advantages__shield.svg',
        title: 'petrovich.advantages.item.2.title',
        text: 'petrovich.advantages.item.2.text',
        color: 'rgba(99, 177, 243, 0.24)',
      },
      {
        id: 3,
        image: '/potrebitelskie_kredity/magnet2/magnet2-advantages__hand.svg',
        title: 'petrovich.advantages.item.3.title',
        text: 'petrovich.advantages.item.3.text',
        color: 'rgba(73, 197, 93, 0.24)',
      },
    ],
    TEXTS: {
      title: 'petrovich.advantages.title',
    },
    GTM: {},
  },
  PRODUCTS: {
    ORDER: 3,
    TABS: [
      {
        id: 1,
        name: 'Онлайн на Финуслугах',
        shortName: 'Онлайн',
        value: 'online',
        configKey: 'PLATFORM',
      },
      {
        id: 2,
        name: 'В отделении банка',
        shortName: 'В отделении',
        value: 'offline',
        configKey: 'NON_PLATFORM',
      },
      // {
      //   id: 3,
      //   name: 'МФО',
      //   shortName: 'МФО',
      //   value: 'mfo',
      //   configKey: 'MFO',
      // },
    ],
    PLATFORM: {
      ID: 31,
      COUNT: 4,
      URL_TEMPLATE:
        'https://lk.finuslugi.ru/credits/new?productId={{entity.productId}}&skipName&silent&registrationMode=gosuslugi&utm_source={{entity.utm_source}}&utm_medium={{entity.utm_medium}}&utm_campaign={{entity.utm_campaign}}',
      URL_TARGET: '_blank',
      URL_PARAMS: {
        utm_source: 'petrovich',
        utm_medium: 'affiliate',
        utm_campaign: 'finuslugi_aff_petrovich_platform_kredity_may-26',
      },
      TEXTS: {
        itemButton: 'landing.products.item.button',
      },
      GTM: {
        clickButton: 'petrovich_products_item',
      },
    },
    NON_PLATFORM: {
      ID: 32,
      COUNT: 4,
      URL_TEMPLATE:
        'https://lk.finuslugi.ru/credits/new?productId={{entity.productId}}&skipName&silent&registrationMode=gosuslugi&utm_source={{entity.utm_source}}&utm_medium={{entity.utm_medium}}&utm_campaign={{entity.utm_campaign}}',
      URL_TARGET: '_blank',
      URL_PARAMS: {
        utm_source: 'petrovich',
        utm_medium: 'affiliate',
        utm_campaign: 'finuslugi_aff_magnit_neplatform_kredity_may-26',
      },
      TEXTS: {
        itemButton: 'landing.products.item.button-nonplatform',
      },
      GTM: {
        clickButton: 'petrovich_products_item',
      },
    },
    MFO: {
      ID: 20,
      COUNT: 20,
      URL_TEMPLATE:
        '{{entity.origin}}?utm_source={{entity.utm_source}}&email={{promotion.email}}&promocode={{promotion.promocode}}&key={{entity.key}}&utm_medium={{entity.utm_medium}}&utm_campaign={{entity.utm_campaign}}',
      URL_TARGET: '_blank',
      URL_PARAMS: {
        utm_source: 'mfo',
        utm_medium: 'affiliate',
        utm_campaign: 'finuslugi_aff_mfo_microkredity_june-30',
      },
      BUTTON: {
        URL: null,
        THEME: 'accent',
        TARGET: '_blank',
        NAVIGATE: '#products',
      },
      TEXTS: {
        title: 'petrovich.products.mfo.title',
        modalTitle: 'mfo.hero.modal-title',
        caption: 'mfo.hero.caption',
        button: 'mfo.hero.button',
        text: 'mfo.hero.text',
        formTitle: 'mfo.hero.formTitle',
        formEmail: 'mfo.hero.formEmail',
        formCode: 'mfo.hero.formCode',
        itemButton: 'mfo.top-products.button',
      },
      GTM: {
        productClick: 'petrovich_mfo_product_click',
        clickFormButton: 'petrovich_mfo_form_product_click',
      },
    },
    TEXTS: {
      title: 'petrovich.products.title',
    },
    GTM: {
      tabChange: 'petrovich_products_tab',
    },
  },
  STEPS: {
    ORDER: 4,
    THEME: 'darkWhite',
    PLATFORM: [
      {
        id: 1,
        text: 'petrovich.steps.item.platform-1.text',
        tooltip: 'petrovich.steps.tooltip',
      },
      { id: 2, text: 'petrovich.steps.item.platform-2.text' },
      { id: 3, text: 'petrovich.steps.item.platform-3.text' },
      { id: 4, text: 'petrovich.steps.item.platform-4.text' },
    ],
    NON_PLATFORM: [
      {
        id: 1,
        text: 'petrovich.steps.item.neplatform-1.text',
        tooltip: 'petrovich.steps.tooltip',
      },
      { id: 2, text: 'petrovich.steps.item.neplatform-2.text' },
      { id: 3, text: 'petrovich.steps.item.neplatform-3.text' },
      { id: 4, text: 'petrovich.steps.item.neplatform-4.text' },
    ],
    BUTTON: {
      URL: null,
      TARGET: '_self',
      NAVIGATE: null,
    },
    TEXTS: {
      title: 'petrovich.steps.title',
      button: 'petrovich.steps.button',
      badge: 'petrovich.steps.badge',
    },
    GTM: {
      clickButton: 'petrovich_steps_button',
    },
  },
  PARTNERS: {
    ORDER: 5,
    ITEMS: [
      {
        id: 1,
        name: 'Газпромбанк',
        bic: '044525823',
      },
      {
        id: 2,
        name: 'Тинькофф',
        bic: '044525974',
      },
      {
        id: 3,
        name: 'Банк Центр-Инвест',
        bic: '046015762',
      },
      {
        id: 5,
        name: 'СинараБанк',
        bic: '046577756',
      },
      {
        id: 6,
        name: 'Совкомбанк',
        bic: '043469743',
      },
      {
        id: 8,
        name: 'Металлинвестбанк',
        bic: '044525176',
      },
    ],
    BUTTON: {
      URL: null,
      TARGET: '_self',
      NAVIGATE: '#products',
    },
    TEXTS: {
      title: 'petrovich.partners.title',
      button: 'petrovich.partners.button',
    },
    GTM: {
      clickButton: 'petrovich_partners_button',
    },
  },
  SUPPORT: {
    ORDER: 6,
    GTM: {
      phoneClick: 'petrovich_support_phone',
      socialClick: 'petrovich_support_social',
    },
  },
  FAQ: {
    ORDER: 7,
    ITEMS: [
      {
        id: 1,
        question:
          'Вклады, кредиты, страховки — онлайн на одной платформе или что такое Финуслуги?',
        answer:
          'Финуслуги — это проект Московской биржи. Банк России включил Финуслуги в реестр финансовых платформ под № 1. На Финуслугах можно выбирать и <a href="https://finuslugi.ru/vklady" target="_blank">открывать вклады</a>, <a href="https://osago.finuslugi.ru/?_ga=2.224549791.483919761.1677767040-118363526.1677677617" target="_blank">покупать страховки</a>, <a href="https://finuslugi.ru/invest/bonds" target="_blank">инвестировать</a>, <a href="https://finuslugi.ru/potrebitelskie_kredity" target="_blank">брать кредиты</a> в разных банках и страховых компаниях и управлять своими продуктами 24/7 онлайн в одном личном кабинете. Все банки на платформе имеют лицензию Банка России, а выписки о сделках можно в любой момент получить на Госуслугах. Деньги на вкладах застрахованы — в каждом банке до 1,4 млн рублей.',
      },
      {
        id: 2,
        question: 'Чем Финуслуги отличаются от маркетплейсов?',
        answer: `В отличие от сайтов-агрегаторов, где можно только посмотреть предложения, а оформлять заявку нужно уже в выбранном банке, на Финуслугах можно не просто сравнить и выбрать оптимальное предложение от банка или страховой, но и тут же все оформить онлайн — не нужно регистрироваться в разных банках, скачивать разные мобильные приложения. Управлять всеми продуктами можно в одном личном кабинете. На платформе вы сразу видите полную информацию о продукте — на Финуслугах нет никаких допусловий и «звездочек». Также пользователи Финуслуг получают:  
          * Эксклюзивные предложения — более выгодные ставки на вклады, чем в самих банках;  
          * Уникальные продукты — можно инвестировать в народные облигации Калининграда.`,
      },
      {
        id: 3,
        question: 'Как стать клиентом?',
        answer:
          'Чтобы начать пользоваться платформой, нужно авторизоваться через подтвержденный профиль с Госуслуг. После этого необходимо один раз встретиться с представителем платформы, чтобы подтвердить личность. По закону это нужно, чтобы мошенники не смогли совершать финансовые операции от вашего лица. Такая двухэтапная регистрация поможет защитить ваши финансы. Чтобы пройти идентификацию, в личном кабинете нажмите кнопку «Запланировать встречу» и выберите удобное место и время. Встреча не займет больше 10-15 минут. А дальше все просто — можно пользоваться всеми продуктами на платформе и управлять ими из одного личного кабинета. Пополнение счета — через систему быстрых платежей и по QR-кодам.',
      },
    ],
    TEXTS: {
      title: 'petrovich.faq.title',
    },
    GTM: {
      linkClick: 'petrovich_faq_link',
    },
  },
  INFO: {
    ORDER: 8,
    IMAGE_SRC: '/potrebitelskie_kredity/petrovich/petrovich-info__image.svg',
    IMAGE_ALT: 'Промокод: FINIK*******',
    BUTTON: {
      URL: null,
      TARGET: '_self',
      NAVIGATE: '#products',
    },
    LINK: {
      URL: 'https://assets.finuslugi.ru/mp-assets/docs/bonus-program-rules/aktsiya-petrovich/pravila-aktsii.pdf',
      TARGET: '_blank',
    },
    TEXTS: {
      subtitle: 'petrovich.info.subtitle',
      caption: 'petrovich.info.caption',
      button: 'petrovich.info.button',
      link: 'petrovich.info.link',
      dates: 'petrovich.info.dates',
    },
    GTM: {
      clickLink: 'petrovich_info_link',
      clickButton: 'petrovich_info_button',
    },
  },
  FOOTER: {
    TEXTS: {
      caption: 'petrovich.footer.caption',
    },
    GTM: {
      clickPhone: 'petrovich_footer_phone',
    },
  },
};
