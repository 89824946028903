import mergeWith from 'lodash/mergeWith';

export const mergeConfig = (target, source) => {
  return mergeWith(target, source, (_, value) => {
    if (Array.isArray(value)) {
      return value;
    }

    return undefined;
  });
};
