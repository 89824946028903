// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GAx\\+k9Z1BleTL11BU2FEwg\\=\\={display:flex;flex-direction:column;min-height:100vh;margin:0;background-color:#f7f8fa}.GAx\\+k9Z1BleTL11BU2FEwg\\=\\= .common-header-logo__icon_aggregator{padding:16px 0;min-width:0!important}.GAx\\+k9Z1BleTL11BU2FEwg\\=\\= *{font-family:\"Roboto\",serif}.jLVT3c8fwX0QOAjb8XO1PQ\\=\\={flex:1;display:flex;flex-direction:column}.rNBYIjOeQtXd1hX8FoaaNg\\=\\={flex:1}.HF7kUc4wIwOrE2G5IYtZLA\\=\\={margin-top:60px}@media screen and (min-width:600px){.HF7kUc4wIwOrE2G5IYtZLA\\=\\={margin-top:120px}}@media (-ms-high-contrast:active),screen and (-ms-high-contrast:none){.rNBYIjOeQtXd1hX8FoaaNg\\=\\={min-height:100vh}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"app": "GAx+k9Z1BleTL11BU2FEwg==",
	"wrapper": "jLVT3c8fwX0QOAjb8XO1PQ==",
	"content": "rNBYIjOeQtXd1hX8FoaaNg==",
	"footer": "HF7kUc4wIwOrE2G5IYtZLA=="
};
module.exports = ___CSS_LOADER_EXPORT___;
