import { init } from '@moex/user-actions-tracker';

init({}, {
  include: [
    'userAgent',
    'language',
    'platform',
    'timezone',
    'timezoneOffset',
    'plugins',
    'screenResolution',
    'deviceMemory',
    'cpuClass',
    'touchSupport',
  ],
  userActions: [
    'click',
    'transition',
  ],
  label: 'mp-credit-landings-showcase',
  tag: 'audit',
});
