function escapeJson(json) {
  // eslint-disable-next-line
  const escapable = /[\\"\x00-\x1f\x7f-\x9f\u00ad\u0600-\u0604\u070f\u17b4\u17b5\u200c-\u200f\u2028-\u202f\u2060-\u206f\ufeff\ufff0-\uffff]/g;
  const meta = {
    // table of character substitutions
    '\b': '\\b',
    '\t': '\\t',
    '\n': '\\n',
    '\f': '\\f',
    '\r': '\\r',
    '"': '\\"',
    '\\': '\\\\',
  };

  escapable.lastIndex = 0;
  return escapable.test(json)
    ? `${json.replace(escapable, (a) => {
      const c = meta[a];
      return typeof c === 'string'
        ? c
        : `\\u${`0000${a.charCodeAt(0).toString(16)}`.slice(-4)}`;
    })}`
    : `${json}`;
}

function getPrefix(prefix, key) {
  return `${prefix ? `${prefix}.` : ''}${key}`;
}

function toKeyMap(obj, prefix = '') {
  if (!obj) return {};
  return Object.entries(obj).reduce((res, [key, value]) => {
    if (typeof value === 'object') {
      return {
        ...res,
        ...toKeyMap(value, getPrefix(prefix, key)),
      };
    }
    return {
      ...res,
      [getPrefix(prefix, key)]: value,
    };
  }, {});
}

const replace = (str, data) => {
  const temp = toKeyMap(data);
  return Object.entries(temp).reduce(
    (res, [key, value]) => res.replace(new RegExp(`{{${key}}}`, 'gm'), escapeJson(value)),
    str,
  );
};

export const templatify = (value, data) => {
  switch (typeof value) {
    case 'object':
      return JSON.parse(replace(JSON.stringify(value), data));
    case 'string':
      return replace(value, data);
    default:
      return value;
  }
};
