export const CALCULATOR = {
  title: 'Калькулятор кредита — рассчитать проценты и переплату по кредиту онлайн',
  canonical: '{{PUBLIC_HOST}}/potrebitelskie_kredity/kreditnyj-kalkulyator/',
  meta: [
    { name: 'description', content: 'Кредитный калькулятор онлайн. Позволяет быстро рассчитать полную стоимость кредита и скачать график платежей по потребительскому кредиту.' },
    { property: 'og:title', content: 'Кредитный калькулятор — Финуслуги' },
    { property: 'og:description', content: 'Кредитный калькулятор онлайн. Позволяет быстро рассчитать полную стоимость кредита и скачать график платежей по потребительскому кредиту.' },
    { property: 'og:image', content: '{{PUBLIC_HOST}}/icons/1200x630-logo.jpg' },
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: '{{PUBLIC_HOST}}/potrebitelskie_kredity/kreditnyj-kalkulyator/' },
    { property: 'og:site_name', content: 'Финуслуги' },
  ],
};
