import { formatPhoneNumber } from '@finuslugi/common-utils';
import { clearPhoneNumber } from './clearPhoneNumber';

/**
 * Хелпер для обработки телефонов
 *
 * @param {string} phone - номер телефона
 *
 * @returns {{ tel: string, number: string }}
 *  tel - очищенный номер под атрибут href в ссылке
 *  number - форматированной номер для отображения
 */
export function getPhone(phone) {
  if (!phone || typeof phone !== 'string') {
    return { tel: '', number: '' };
  }

  return {
    tel: clearPhoneNumber(phone),
    number: formatPhoneNumber(phone),
  };
}
