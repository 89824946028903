export default {
  get(state) {
    return state.form;
  },
  getQuery(state) {
    if (state.form) {
      const {
        amount,
        rate,
        term: {
          value: termValue,
          type: termType,
        },
      } = state.form;

      return `amount=${amount}&rate=${rate}&termValue=${termValue}&termType=${termType}`;
    }

    return '';
  },
};
