//
//
//
//
//
//
//

// eslint-disable-next-line import/no-unresolved
import FrameworkIcons from '@moex/ui-framework/dist/icons.svg?inline';

export default {
  name: 'LandingsLayout',
  components: {
    FrameworkIcons,
  },
};
