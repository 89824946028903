import { get } from '@finuslugi/browser-id';

export default ({ $gtm, $config }, inject) => {
  const { browserId, sessionId } = get();

  // Проксируем свойства, чтоб было проще обращаться
  const gtme = new Proxy({}, {
    get(_, prop) {
      return function fn(action, data = {}) {
        if (!$gtm) {
          console.error(`Trying to send GTM event "${action}" within namespace "${prop}", but $gtm plugin is not initialized`);
          return;
        }

        const payload = {
          event: 'data_event',
          session_id: sessionId,
          browser_id: browserId,
          ...data,
          eventAction: action,
          eventCategory: prop,
        };
        if ($config.IS_DEV) {
          console.groupCollapsed(
            `%c[GTM]%c Push event %c${action} %cin category %c${prop} %c`,
            'background: #35495e; padding: 2px 4px; border-radius: 4px; color: #fff',
            'background: transparent',
            'font-weight: bold',
            'font-weight: normal',
            'font-weight: bold',
            'font-weight: normal',
          );
          console.log('Event payload:');
          console.dir(payload);
          console.groupEnd();
        }

        $gtm.push(payload);
      };
    },
  });

  inject('gtme', gtme);
};
