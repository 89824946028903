import round from 'lodash/round';
import { Term, Frequency } from '@/utils/constants';

const multiplier = {
  [Term.Month]: 1,
  [Term.Year]: 12,
};

const Storage = new Map();

function roundNum(number) {
  return round(number, 2);
}

export function getTermInMonths({ value, type } = {}) {
  return value * multiplier[type];
}

export function getTermInYears(term = {}) {
  return Math.floor(getTermInMonths(term) / 12);
}

function getHash({
  amount,
  term,
  rate,
  insurance = 0,
  additionalServices = [],
}) {
  return JSON.stringify({
    amount,
    term,
    rate,
    insurance,
    additionalServices,
  });
}

export function getServiceAmount(service, { amount, term, bankInfo }, asIs) {
  const termInMonths = getTermInMonths(term);
  let serviceAmount = roundNum(service.amount || service.rate * amount / 100);

  if (bankInfo && bankInfo.bic === '046577756' && service.frequency === Frequency.Single) {
    serviceAmount *= getTermInYears(term);
    return roundNum(serviceAmount);
  }

  if (!asIs) {
    switch (service.frequency) {
      case Frequency.Monthly:
        serviceAmount *= termInMonths;
        break;
      case Frequency.Annual:
        serviceAmount *= (termInMonths / 12);
        break;
      default:
        break;
    }
  }

  return roundNum(serviceAmount);
}

export function getServiceList(offer, asIs) {
  if (!offer) return [];

  return (offer.additionalServices || []).map((service) => ({
    ...service,
    amount: getServiceAmount(service, offer, asIs),
  }));
}

export function getServiceListAmount(offer) {
  return getServiceList(offer).reduce((result, service) => {
    const value = service.includedInLoanAmount ? service.amount : 0;

    return roundNum(result + value);
  }, 0);
}

export function getInsuranceAmount(offer) {
  if (!offer.insurance) return 0;

  const { amount, insurance, term } = offer;
  const serviceListAmount = getServiceListAmount(offer);
  const termInYears = getTermInMonths(term) / 12;
  const yearRate = (insurance.rate / 100) * termInYears;

  return roundNum((amount + serviceListAmount) * yearRate / (100 - yearRate));
}

export function getCreditAmount(offer) {
  const insuranceAmount = getInsuranceAmount(offer);
  const serviceListAmount = getServiceListAmount(offer);

  return roundNum(offer.amount + insuranceAmount + serviceListAmount);
}

export function getMonthlyPayment(offer) {
  const amount = getCreditAmount(offer);
  const duration = getTermInMonths(offer.term);
  const monthlyRate = offer.rate / (100 * 12);
  const progressRate = (1 + monthlyRate) ** duration;
  const k = (monthlyRate * progressRate) / (progressRate - 1);
  const monthly = roundNum(amount * k);
  const amountWithPercents = roundNum(monthly * duration);
  const percents = roundNum(amountWithPercents - amount);
  const amountPercentage = roundNum(amount / amountWithPercents);
  const percentPercentage = 1 - amountPercentage;

  return {
    monthly,
    monthlyRate,
    duration,
    amountWithPercents,
    percents,
    amountPercentage,
    percentPercentage,
  };
}

export const getCreditPayment = (offer) => {
  const hash = getHash(offer);
  const saved = Storage.get(hash);

  if (saved) return saved;

  const result = {
    serviceList: getServiceList(offer),
    payment: getMonthlyPayment(offer),
    amount: getCreditAmount(offer),
    services: getServiceListAmount(offer),
    insurance: getInsuranceAmount(offer),
  };

  Storage.set(hash, result);

  return result;
};
