export const THOUSAND_BANKS = {
  CORE: {
    PAGE_GTM_CATEGORY: 'credits_1000banks_landing',
    PAGE_GTM_CLICK: 'credits_1000banks_landing_click',
  },
  META: {
    TITLE: 'Оформите кредит и получите бонус 3 000 ₽',
    CANONICAL: '{{PUBLIC_HOST}}/potrebitelskie_kredity/landing/1000-banks',
    ADDITIONAL_LINKS: [],
    ADDITIONAL_METAS: [
      {
        property: 'og:site-name',
        content: 'Финуслуги | Оформите кредит и получите бонус 3 000 ₽',
      },
      {
        property: 'og:description',
        content:
          'Финуслуги - маркетплейс финансовых услуг от Мосбиржи, платформа №1 в реестре ЦБ РФ.',
      },
      { property: 'og:type', content: 'website' },
    ],
  },
  HERO: {
    ORDER: 1,
    BUTTON:
      'https://lk.finuslugi.ru/credits/new?productId=362de0c3-f253-4b0b-a04a-ac1b612c1a82&skipName&silent&registrationMode=gosuslugi&utm_source=1000bankov_banner_kredit&utm_medium=affiliate&utm_campaign=finuslugi_aff_1000bankov_platform_kredity_apr-11',
    BUTTON_TARGET: '_blank',
    BUTTON_THEME: 'accent',
    LINK: 'https://assets.finuslugi.ru/mp-assets/docs/bonus-program-rules/pravila-aktsii-perviu-potreb-kredit-cherez-1000bankov/pravila-aktrsii.pdf',
    LINK_TARGET: '_blank',
    CREDIT: {
      label: 'Только на Финуслугах',
      title: 'Кредит Честный (без страховки)',
      caption: 'Без залога',
      rate: 13,
      amount: 300000,
      payment: 2680,
      bic: '046015762',
    },
    TEXTS: {
      title: '1000banks.hero.title',
      caption: '1000banks.hero.caption',
      promoText: '1000banks.hero.promoText',
      promocode: '1000banks.hero.promocode',
      button: '1000banks.hero.button',
      link: '1000banks.hero.link',
      dates: '1000banks.hero.dates',
    },
    GTM: {
      clickButton: '1000banks_hero_button',
      clickLink: '1000banks_hero_link',
    },
  },
  CALC: {
    ORDER: 2,
    IMAGE: '/potrebitelskie_kredity/1000banks/1000banks-calc__image.svg',
    BUTTON:
      'https://lk.finuslugi.ru/credits/new?productId=362de0c3-f253-4b0b-a04a-ac1b612c1a82&skipName&silent&registrationMode=gosuslugi&utm_source=1000bankov_kredit&utm_medium=affiliate&utm_campaign=finuslugi_aff_1000bankov_platform_kredity_apr-11',
    BUTTON_TARGET: '_blank',
    TEXTS: {
      title: '1000banks.calc.title',
      button: '1000banks.calc.button',
    },
    GTM: {
      clickButton: '1000banks_calc_button',
    },
  },
  HOW_TO: {
    ORDER: 3,
    ITEMS: [
      {
        id: 1,
        step: '1000banks.how-to.step1',
        image: '/potrebitelskie_kredity/1000banks/1000banks-step__1.svg',
        title: '1000banks.how-to.item.1.title',
        text: '1000banks.how-to.item.1.text',
      },
      {
        id: 2,
        step: '1000banks.how-to.step2',
        image: '/potrebitelskie_kredity/1000banks/1000banks-step__2.svg',
        title: '1000banks.how-to.item.2.title',
        text: '1000banks.how-to.item.2.text',
      },
      {
        id: 3,
        step: '1000banks.how-to.step3',
        image: '/potrebitelskie_kredity/1000banks/1000banks-step__3.svg',
        title: '1000banks.how-to.item.3.title',
        text: '1000banks.how-to.item.3.text',
      },
      {
        id: 4,
        step: '1000banks.how-to.step4',
        image: '/potrebitelskie_kredity/1000banks/1000banks-step__4.svg',
        title: '1000banks.how-to.item.4.title',
        text: '1000banks.how-to.item.4.text',
      },
    ],
    TEXTS: {
      title: '1000banks.how-to.title',
    },
  },
  ABOUT: {
    ORDER: 4,
    BUTTON:
      'https://lk.finuslugi.ru/credits/new?productId=362de0c3-f253-4b0b-a04a-ac1b612c1a82&skipName&silent&registrationMode=gosuslugi&utm_source=1000bankov_kredit&utm_medium=affiliate&utm_campaign=finuslugi_aff_1000bankov_platform_kredity_apr-11',
    BUTTON_THEME: 'accent',
    BUTTON_TARGET: '_blank',
    IMAGE: '/potrebitelskie_kredity/1000banks/1000banks-about__image.svg',
    IMAGE_MOBILE:
      '/potrebitelskie_kredity/1000banks/1000banks-about__image_mobile.svg',
    TEXTS: {
      title: '1000banks.about.title',
      button: '1000banks.about.button',
    },
    GTM: {
      clickButton: '1000banks_about_click',
    },
  },
  ADVANTAGES: {
    ORDER: 5,
    BUTTON:
      'https://lk.finuslugi.ru/credits/new?productId=362de0c3-f253-4b0b-a04a-ac1b612c1a82&skipName&silent&registrationMode=gosuslugi&utm_source=1000bankov_kredit&utm_medium=affiliate&utm_campaign=finuslugi_aff_1000bankov_platform_kredity_apr-11',
    BUTTON_TARGET: '_blank',
    BUTTON_THEME: 'accent',
    ITEMS: [
      {
        id: 1,
        image: '/potrebitelskie_kredity/magnet2/magnet2-advantages__star.svg',
        title: '1000banks.advantages.item.1.title',
        text: '1000banks.advantages.item.1.text',
        color: 'rgba(251, 150, 57, 0.24)',
      },
      {
        id: 2,
        image: '/potrebitelskie_kredity/magnet2/magnet2-advantages__shield.svg',
        title: '1000banks.advantages.item.2.title',
        text: '1000banks.advantages.item.2.text',
        color: 'rgba(99, 177, 243, 0.24)',
      },
      {
        id: 3,
        image: '/potrebitelskie_kredity/magnet2/magnet2-advantages__hand.svg',
        title: '1000banks.advantages.item.3.title',
        text: '1000banks.advantages.item.3.text',
        color: 'rgba(73, 197, 93, 0.24)',
      },
      {
        id: 4,
        image: '/potrebitelskie_kredity/magnet2/magnet2-advantages__stars.svg',
        title: '1000banks.advantages.item.4.title',
        text: '1000banks.advantages.item.4.text',
        color: 'rgba(255, 5, 8, 0.24)',
      },
    ],
    TEXTS: {
      title: '1000banks.advantages.title',
      button: '1000banks.advantages.button',
    },
    GTM: {
      clickButton: '1000banks_advantages_clickButton',
    },
  },
  DISCLAIMER: {
    ORDER: 6,
    TEXTS: {
      title: '1000banks.disclaimer.title',
      text: '1000banks.disclaimer.text',
    },
  },
  FOOTER: {
    TEXTS: {
      caption: '1000banks.footer.caption',
    },
    GTM: {
      clickPhone: '1000banks_footer_phone',
    },
  },
};
