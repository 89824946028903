//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable vue/require-default-prop */

export default {
  name: 'LocaleText',
  props: {
    field: {
      type: String,
      default: null,
    },
    fieldText: {
      type: String,
      default: null,
    },
    count: {
      type: Number,
    },
    tag: {
      type: String,
      default: 'span',
    },
    params: {
      type: Object,
    },
    activeLink: {
      type: Boolean,
      default: true,
    },
    fallbackKey: {
      type: String,
      default: '',
    },
  },
  computed: {
    enrichKey() {
      if (!this.fallbackKey || this.isRich(this.field) || this.$te(this.field)) return this.field;

      return this.fallbackKey;
    },
    key() {
      let field = this.enrichKey;

      if (this.isRich(field)) {
        field += '.markdown';
      }

      return field;
    },
    text() {
      if (this.fieldText) {
        return this.fieldText;
      }

      if (typeof this.count === 'number') {
        return this.$tc(this.key, this.count, this.params);
      }

      return this.$t(this.key, this.params);
    },
    useMarkdown() {
      return this.fieldText || this.key?.endsWith('.markdown');
    },
  },
  methods: {
    onClick({ target }) {
      if (target.tagName.toLowerCase() === 'a') {
        this.$emit('click-link', target.id);
      }
    },
    isRich(key) {
      return this.$te(`${key}.markdown`);
    },
  },
};
