import localConfig from '@/local/config';
import { mergeConfig, templatify } from '@/utils/helpers';

export default async (ctx, inject) => {
  const { $config, $axios } = ctx;
  const { PL_LK_API, CONFIGS: { SLUG } } = $config;

  try {
    const { data: siteConfig } = await $axios.get(`${PL_LK_API}/api/v1/content/site-configs/${SLUG}`);

    const configFields = Object.entries(siteConfig).reduce((res, [key, value]) => {
      const lowerKey = key.toLowerCase();

      if (lowerKey.startsWith('sc_')) {
        return { ...res, [key.slice(3)]: value };
      }

      return res;
    }, {});

    const env = { ...$config, ...configFields };

    inject('siteConfig', templatify(mergeConfig(localConfig, configFields), env));
  } catch (error) {
    console.error(error?.message);
    inject('siteConfig', templatify(localConfig, $config));
  }
};
