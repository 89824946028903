import { HEAD } from './head';
import { ADFOX_PARAMS } from './adfox-params';
import { HEADER } from './header';
import { FOOTER } from './footer';
import { MAIN } from './main';
import { REGISTRATION } from './registration';
import { LANDING } from './landing';
import { MAGNET } from './magnet';
import { MAGNET2 } from './magnet2';
import { TELE2 } from './tele2';
import { LAMODA } from './lamoda';
import { THOUSAND_BANKS } from './thousand-banks';
import { PETROVICH } from './petrovich';
import { INTERFAX } from './interfax';
import { MFO } from './mfo';

export default {
  OPEN_ONLINE_URL:
    '{{CREDITS_HOST}}/new?aggregatorId=moex-sc-kreditnyj-kalkulyator',
  ADFOX_PARAMS,
  HEAD,
  HEADER,
  FOOTER,
  MAIN,
  REGISTRATION,
  LANDING,
  MAGNET,
  MAGNET2,
  TELE2,
  LAMODA,
  THOUSAND_BANKS,
  PETROVICH,
  INTERFAX,
  MFO,
};
