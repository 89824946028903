import {
  createPlugin,
  DictionaryModule,
  InboxModule,
  LayoutModule,
  ProfileModule,
} from '@finuslugi/nuxt-modules';
import '@finuslugi/nuxt-modules/dist/modules.css';
import Vue from 'vue';

export default (nuxtContext, inject) => {
  createPlugin(nuxtContext, inject, [
    InboxModule,
    ProfileModule,
    DictionaryModule,
    LayoutModule,
  ], Vue);
};
