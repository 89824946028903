var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('UiApp',{staticClass:"common-layout",class:_vm.$style.app,attrs:{"name":"app","theme":"test"}},[_c('ClientOnly',[_c('CommonLayoutProvider',{attrs:{"cms-gateway-base-url":((_vm.$config.PL_LK_API) + "/api/v1")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var isPending = ref.isPending;
        var headerConfig = ref.headerConfig;
        var sidebarConfig = ref.sidebarConfig;
        var isSidebarOpen = ref.isSidebarOpen;
        var toggleSidebar = ref.toggleSidebar;
        var tabbarConfig = ref.tabbarConfig;
return _c('div',{class:_vm.$style.wrapper},[_c('CommonHeader',{attrs:{"z-index":111,"z-index-dropdown":115,"config":headerConfig,"current-resource":_vm.creditDashboardLink,"is-pending":isPending,"is-logged-in":false,"aggregator":_vm.aggregator,"modules":{}},on:{"on-burger-click":function($event){return toggleSidebar(true)},"on-logout-click":function () { return null; }}}),_vm._v(" "),(_vm.isMounted)?_c('CommonSidebar',{attrs:{"z-index":150,"config":sidebarConfig,"current-resource":_vm.creditDashboardLink,"is-open":isSidebarOpen,"is-pending":isPending,"is-logged-in":false,"aggregator":_vm.aggregator,"modules":{}},on:{"on-logout-click":function () { return null; },"on-close":function($event){return toggleSidebar(false)}}}):_vm._e(),_vm._v(" "),(_vm.isMounted)?_c('CommonTabbar',{attrs:{"z-index":110,"config":tabbarConfig,"current-resource":_vm.creditDashboardLink,"is-sidebar-open":isSidebarOpen,"is-pending":isPending,"is-logged-in":false,"aggregator":_vm.aggregator,"modules":{}},on:{"on-logout-click":function () { return null; }}}):_vm._e(),_vm._v(" "),_c('div',{class:_vm.$style.content},[_c('Nuxt')],1),_vm._v(" "),_c('TheFooter',{class:_vm.$style.footer})],1)}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }