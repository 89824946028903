//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import { getPhone, isOutsideClick } from '@/utils/helpers';

// eslint-disable-next-line
import TheSupportPhoneLink from '@/components/Layout/TheSupportPhoneLink';
import LocaleText from '@/components/common/LocaleText';

export default {
  name: 'TheFooter',
  components: {
    TheSupportPhoneLink,
    LocaleText,
  },
  data() {
    return {
      userAgreementLink: `${this.$config.S3_ASSETS}/user-agreement.pdf`,
      privacyPolicyLink: `${this.$config.S3_ASSETS}/privacy-policy.pdf`,
      platformRulesLink: `${this.$config.S3_ASSETS}/platform-rules.pdf`,
      informationDisclosureLink: `${this.$config.SHOWCASE_ROOT_URL}/disclosure`,
      helpLink: `${this.$config.SHOWCASE_ROOT_URL}/help`,
      fraudulentActivitiesLink: `${this.$config.SHOWCASE_ROOT_URL}/fraud`,
      siteRegulationsLink: `${this.$config.S3_ASSETS}/site-regulations.pdf`,
      address: 'г. Москва, ул. Воздвиженка, д. 4/7, стр. 1',
      isTooltipShow: false,
    };
  },
  computed: {
    ...mapGetters({
      supportPhone: 'dictionary/supportPhone',
      supportEmail: 'dictionary/supportEmail',
      supportPhoneAlt: 'dictionary/supportPhoneAlt',
    }),
    phone() {
      return getPhone(this.supportPhone);
    },
    phoneAlt() {
      return getPhone(this.supportPhoneAlt);
    },
    currentYear() {
      return new Date().getFullYear();
    },
  },
  mounted() {
    window.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    this.clearTimeoutIfExist();
    window?.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    toggleCraftTalk() {
      this.$craftTalk.toggle();
    },
    copyEmail() {
      this.emailTooltipText = 'Скопировано';
      if (window.navigator.clipboard) {
        window.navigator.clipboard.writeText(this.supportEmail);
        this.showTooltip();
      }
    },
    showTooltip() {
      this.isTooltipShow = true;

      this.clearTimeoutIfExist();
      this.$_timeoutId = setTimeout(this.resetEmailTooltipText, 1000);
    },
    // Тултип скрывается, когда в него не передан контент.
    // Скрываем тултип по прошествию 2 секунд, или если пользователь отвел курсор.
    resetEmailTooltipText() {
      this.emailTooltipText = '';
      this.$_timeoutId = setTimeout(() => {
        this.isTooltipShow = false;
      }, 2000);
    },
    clearTimeoutIfExist() {
      if (this.$_timeoutId) {
        clearTimeout(this.$_timeoutId);
      }
    },
    handleClickOutside(evt) {
      this.$nextTick(() => {
        const tooltip = this.$refs.emailTooltip;
        if (this.isTooltipShow && isOutsideClick(tooltip.parentElement, evt)) {
          this.isTooltipShow = false;
        }
      });
    },
  },
};
