export const CREDISTORY = {
  GPB: {
    BANK: {
      NAME: 'Газпромбанк',
      BIC: '044525823',
    },
    PARAMETERS: {
      DEFAULT_AMOUNT: 300000,
      DEFAULT_TERM: 36,
      DEFAULT_RATE: 5.9,
      MIN_AMOUNT: 300000,
      MAX_AMOUNT: 5000000,
      MIN_TERM: 1,
      MAX_TERM: 60,
    },
    URL: '/credits/new?skipName=true&silent=true&registrationMode=gosuslugi&utm_source=okb_gpb&utm_medium=affiliate&utm_campaign=finuslugi_aff_okb_gpb_platform_kredity_mar-22',
  },
  ROSBANK: {
    BANK: {
      NAME: 'Росбанк',
      BIC: '044525256',
    },
    PARAMETERS: {
      DEFAULT_AMOUNT: 50000,
      DEFAULT_TERM: 36,
      DEFAULT_RATE: 0.9,
      MIN_AMOUNT: 30000,
      MAX_AMOUNT: 3000000,
      MIN_TERM: 13,
      MAX_TERM: 60,
    },
    URL: '/registration?silent&isSimple&skipName=true&returnTo=/credits/new&utm_source=credistory&utm_medium=affiliate&utm_campaign=finuslugi_aff_kredity_credistory_rosbank_may-16-2023',
  },
  URALSIB: {
    BANK: {
      NAME: 'Уралсиб',
      BIC: '044525787',
    },
    PRODUCT_TYPE: 'CREDIT_CARD',
    PARAMETERS: {
      DEFAULT_AMOUNT: 300000,
      DEFAULT_TERM: 36,
      DEFAULT_RATE: 19.9,
      MIN_AMOUNT: 300000,
      MAX_AMOUNT: 3000000,
      MIN_TERM: 1,
      MAX_TERM: 120,
      MIN_RATE: 19.9,
      MAX_RATE: 59.9,
    },
    URL: '/registration?silent=true&isSimple=true&skipName=true&returnTo=/credits/new&utm_source=credistory&utm_medium=affiliate&utm_campaign=finuslugi_aff_kredity_credistory_uralsib_may-16-2023',
  },
  MKB: {
    BANK: {
      NAME: 'Московский кредитный банк',
      BIC: '044525659',
    },
    PARAMETERS: {
      DEFAULT_AMOUNT: 300000,
      DEFAULT_TERM: 36,
      DEFAULT_RATE: 4.5,
      MIN_AMOUNT: 100000,
      MAX_AMOUNT: 5000000,
      MIN_TERM: 6,
      MAX_TERM: 60,
    },
    URL: '/registration?silent=true&isSimple=true&skipName=true&returnTo=/credits/new&utm_source=credistory&utm_medium=affiliate&utm_campaign=finuslugi_aff_kredity_credistory_mkb_may-16-2023',
  },
  POIDEM: {
    BANK: {
      NAME: 'Пойдем',
      BIC: '044525732',
    },
    PARAMETERS: {
      DEFAULT_AMOUNT: 300000,
      DEFAULT_TERM: 36,
      DEFAULT_RATE: 5.5,
      MIN_AMOUNT: 150000,
      MAX_AMOUNT: 500000,
      MIN_TERM: 13,
      MAX_TERM: 59,
    },
    URL: '/registration?silent=true&isSimple=true&skipName=true&returnTo=/credits/new&utm_source=credistory&utm_medium=affiliate&utm_campaign=finuslugi_aff_kredity_credistory_poidem_may-16-2023',
  },
  BANK: {
    NAME: 'Тинькофф',
    BIC: '044525974',
  },
  PARAMETERS: {
    DEFAULT_AMOUNT: 300000,
    DEFAULT_TERM: 36,
    DEFAULT_RATE: 7.9,
    MIN_AMOUNT: 100000,
    MAX_AMOUNT: 2000000,
    MIN_TERM: 1,
    MAX_TERM: 36,
  },
  AMOUNT_OPTIONS: [
    { to: 20, step: 1 },
    { to: 200, step: 10 },
    { to: 2000, step: 100 },
    { to: 20000, step: 1000 },
    { to: 200000, step: 10000 },
    { to: 1000000, step: 50000 },
    { to: 5000000, step: 100000 },
    { to: 20000000, step: 1000000 },
  ],
  TERM_OPTIONS: [
    { to: 60, step: 1 },
  ],
  PAYMENT_OPTIONS: [
    { to: 10, step: 1 },
    { to: 100, step: 10 },
    { to: 100000, step: 100 },
    { to: 1000000, step: 10000 },
    { to: 10000000, step: 100000 },
  ],
  RATE_OPTIONS: [
    { to: 100, step: 0.1 },
  ],
};
