export const FOOTER = {
  ADVANTAGES: [
    {
      ID: 1,
      LOGO: '{{S3_ASSETS}}/credits/landings/calculator/BankOfRussia.svg',
      NAME: 'Банк России',
      TITLE: 'Нам можно доверять',
      DESCRIPTION: 'footer.advantages.1.markdown',
    },
    {
      ID: 2,
      LOGO: '{{S3_ASSETS}}/credits/landings/calculator/CheckIcon.svg',
      NAME: 'Лучшие предложения',
      TITLE: 'Лучшие предложения',
      DESCRIPTION: 'footer.advantages.2.markdown',
    },
    {
      ID: 3,
      LOGO: '{{S3_ASSETS}}/credits/landings/calculator/TimeIcon.svg',
      NAME: 'Экономим ваше время',
      TITLE: 'Экономим ваше время',
      DESCRIPTION: 'footer.advantages.3.markdown',
    },
  ],
};
