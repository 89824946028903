export const MFO = {
  CORE: {
    PAGE_GTM_CATEGORY: 'credits_mfo_landing',
    PAGE_GTM_CLICK: 'credits_mfo_landing_click',
    DATA_SOURCE: 'MFO_BONUS',
  },
  META: {
    TITLE: 'Оформите выгодный займ на Финуслугах',
    CANONICAL: '{{PUBLIC_HOST}}/potrebitelskie_kredity/landing/mfo',
    ADDITIONAL_LINKS: [],
    ADDITIONAL_METAS: [
      {
        property: 'og:site-name',
        content: 'Финуслуги | Оформите выгодный займ на Финуслугах',
      },
      {
        property: 'og:description',
        content:
          'Финуслуги - маркетплейс финансовых услуг от Мосбиржи, платформа №1 в реестре ЦБ РФ.',
      },
      { property: 'og:type', content: 'website' },
    ],
  },
  HERO: {
    ORDER: 1,
    BUTTON: {
      URL: null,
      THEME: 'accent',
      TARGET: '_blank',
      NAVIGATE: '#top_products',
    },
    TEXTS: {
      title: 'mfo.hero.title',
      modalTitle: 'mfo.hero.modal-title',
      caption: 'mfo.hero.caption',
      button: 'mfo.hero.button',
      text: 'mfo.hero.text',
      formTitle: 'mfo.hero.formTitle',
      formEmail: 'mfo.hero.formEmail',
      formCode: 'mfo.hero.formCode',
    },
    GTM: {
      clickButton: 'mfo_hero_button',
    },
  },
  PRODUCT_SORT: [
    8, 48, 10, 11, 19, 107, 126, 131, 151, 356, 337, 372, 66, 366, 346, 252, 28,
    344, 350, 369,
  ],
  TOP_PRODUCTS: {
    ORDER: 2,
    ID: 'top_products',
    LIST_ID: 24,
    FROM: 0,
    COUNT: 20,
    DISPLAY_COUNT: 4,
    URL_TEMPLATE:
      '{{entity.origin}}?utm_source={{entity.utm_source}}&t3={{promotion.email}}&t4={{promotion.promocode}}&key={{entity.key}}&utm_medium={{entity.utm_medium}}&utm_campaign={{entity.utm_campaign}}',
    URL_TARGET: '_blank',
    URL_PARAMS: {
      utm_source: 'mfo',
      utm_medium: 'affiliate',
      utm_campaign: 'finuslugi_aff_mfo_microkredity_june-30',
    },
    TEXTS: {
      title: 'mfo.top-products.title',
      itemButton: 'mfo.top-products.button',
    },
    GTM: {
      productClick: 'mfo_product_click',
    },
  },
  PRODUCT_INFO: {
    ORDER: 3,
    LOGO: '/potrebitelskie_kredity/common/landing-mfo-zaymer-logo.svg',
    LOGO_MOBILE:
      '/potrebitelskie_kredity/common/landing-mfo-zaymer-logo_mobile.svg',
    LOGO_ALT: 'Займер',
    RATE: 20.99,
    AMOUNT: 10600000,
    TAGS: ['mfo.product-info.tag.1', 'mfo.product-info.tag.2'],
    BUTTON:
      'http://54081f.binomlink.com/click.php?key=nqoh8vqalcgfsli6r1pr&t3={{promotion.email}}&t4={{promotion.promocode}}&utm_source=mfo&utm_medium=affiliate&utm_campaign=finuslugi_aff_mfo_microkredity_june-30',
    BUTTON_TARGET: '_blank',
    BUTTON_THEME: 'accent',
    TEXTS: {
      title: 'mfo.product-info.title',
      rate: 'mfo.product-info.rate',
      payment: 'mfo.product-info.payment',
      button: 'mfo.product-info.button',
    },
    GTM: {
      clickButton: 'mfo_product_info_click',
    },
  },
  PRODUCTS: {
    ORDER: 4,
    ID: 'products',
    LIST_ID: 24,
    FROM: 4,
    COUNT: 20,
    URL_TEMPLATE:
      '{{entity.origin}}?utm_source={{entity.utm_source}}&t3={{promotion.email}}&t4={{promotion.promocode}}&key={{entity.key}}&utm_medium={{entity.utm_medium}}&utm_campaign={{entity.utm_campaign}}',
    URL_TARGET: '_blank',
    URL_PARAMS: {
      utm_source: 'mfo',
      utm_medium: 'affiliate',
      utm_campaign: 'finuslugi_aff_mfo_microkredity_june-30',
    },
    TEXTS: {
      itemButton: 'mfo.top-products.button',
    },
    GTM: {
      productClick: 'mfo_product_click',
    },
  },
  FOOTER: {
    LINKS1: [
      {
        id: 1,
        text: 'mfo.footer.support',
        to: 'https://finuslugi.ru/help',
      },
      {
        id: 2,
        text: 'mfo.footer.mail',
        to: 'mailto:service@finuslugi.ru',
      },
      {
        id: 3,
        text: 'mfo.footer.phone1',
        to: 'tel:88001234578',
      },
      {
        id: 4,
        text: 'mfo.footer.phone2',
        to: 'tel:+74951234567',
      },
    ],
    LINKS2: [
      {
        id: 1,
        text: 'mfo.footer.link1',
        to: 'https://finuslugi.ru/disclosure',
      },
      {
        id: 2,
        text: 'mfo.footer.link2',
        to: 'https://assets.finuslugi.ru/mp-assets/user-agreement.pdf',
      },
      {
        id: 3,
        text: 'mfo.footer.link3',
        to: 'https://assets.finuslugi.ru/mp-assets/platform-rules.pdf',
      },
      {
        id: 4,
        text: 'mfo.footer.link4',
        to: 'https://finuslugi.ru/fraud',
      },
    ],
    SOCIALS: [
      {
        id: 'vk',
        image: '/potrebitelskie_kredity/common/landing-mfo-vk-logo.svg',
        to: 'https://vk.com/finuslugiru',
      },
      {
        id: 'telegram',
        image: '/potrebitelskie_kredity/common/landing-mfo-tg-logo.svg',
        to: 'https://t.me/MoexFinuslugi',
      },
      {
        id: 'zen',
        image: '/potrebitelskie_kredity/common/landing-mfo-zen-logo.svg',
        to: 'https://zen.yandex.ru/finuslugi',
      },
    ],
    TEXTS: {
      address: 'mfo.footer.address',
      caption: 'mfo.footer.caption',
    },
    GTM: {
      linkClick: 'mfo_footer_link_click',
    },
  },
};
