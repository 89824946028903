import { PHOTO } from './photo';

export const MAIN = {
  PHOTO,
  LEAD_ID: 1646,
  FORM: {
    AMOUNT: {
      DEFAULT: 300000,
      MIN: 1,
      MAX: 5000000,
    },
    TERM: {
      DEFAULT: 3,
      OPTIONS: [
        { id: 1, name: '1 месяц', duration: { value: 1, type: 'MONTH' } },
        { id: 2, name: '2 месяца', duration: { value: 2, type: 'MONTH' } },
        { id: 3, name: '3 месяца', duration: { value: 3, type: 'MONTH' } },
        { id: 4, name: '4 месяца', duration: { value: 4, type: 'MONTH' } },
        { id: 5, name: '5 месяца', duration: { value: 5, type: 'MONTH' } },
        { id: 6, name: '6 месяцев', duration: { value: 6, type: 'MONTH' } },
        { id: 7, name: '7 месяцев', duration: { value: 7, type: 'MONTH' } },
        { id: 8, name: '8 месяцев', duration: { value: 8, type: 'MONTH' } },
        { id: 9, name: '9 месяцев', duration: { value: 9, type: 'MONTH' } },
        { id: 10, name: '10 месяцев', duration: { value: 10, type: 'MONTH' } },
        { id: 11, name: '11 месяцев', duration: { value: 11, type: 'MONTH' } },
        { id: 12, name: '1 год', duration: { value: 1, type: 'YEAR' } },
        { id: 18, name: '1,5 года', duration: { value: 1.5, type: 'YEAR' } },
        { id: 24, name: '2 года', duration: { value: 2, type: 'YEAR' } },
        { id: 36, name: '3 года', duration: { value: 3, type: 'YEAR' } },
        { id: 48, name: '4 года', duration: { value: 4, type: 'YEAR' } },
        { id: 60, name: '5 лет', duration: { value: 5, type: 'YEAR' } },
        { id: 78, name: '6 лет', duration: { value: 6, type: 'YEAR' } },
        { id: 84, name: '7 лет', duration: { value: 7, type: 'YEAR' } },
        { id: 96, name: '8 лет', duration: { value: 8, type: 'YEAR' } },
        { id: 108, name: '9 лет', duration: { value: 9, type: 'YEAR' } },
        { id: 120, name: '10 лет', duration: { value: 10, type: 'YEAR' } },
      ],
    },
    RATE: {
      DEFAULT: 15,
      MIN: 1,
      MAX: 99,
    },
  },
};
