export const PHOTO = {
  src: '{{S3_ASSETS}}/credits/landings/calculator/MainBackground.png',
  srcset: `
      {{S3_ASSETS}}/credits/landings/calculator/MainBackground_600x426.png 600w,
      {{S3_ASSETS}}/credits/landings/calculator/MainBackground_1200x853.png 600w 2x,
      {{S3_ASSETS}}/credits/landings/calculator/MainBackground_900x639.png 900w,
      {{S3_ASSETS}}/credits/landings/calculator/MainBackground_1980x1408.png 900w 2x,
      {{S3_ASSETS}}/credits/landings/calculator/MainBackground_1200x853.png 1200w,
      {{S3_ASSETS}}/credits/landings/calculator/MainBackground.png 1200w 2x,
      {{S3_ASSETS}}/credits/landings/calculator/MainBackground_1980x1408.png 1980w,
      {{S3_ASSETS}}/credits/landings/calculator/MainBackground.png 1980w 2x,
      {{S3_ASSETS}}/credits/landings/calculator/MainBackground.png 4320w
    `,
  sizes: `
      (max-width: 600px) 600w,
      (max-width: 900px) 900w,
      (max-width: 1200px) 1200w,
      (max-width: 1800px) 1980w,
      4320w,
    `,
  alt: 'Фоновая картинка',
};
