import { CookieAggregator } from './CookieAggregator';

export const createCPACookie = (cpaConfig, query) => {
  const { name = 'cpa_click', options } = cpaConfig || {};
  const { raw: _raw, ...params } = CookieAggregator.map.in(
    Object.entries(query).reduce(
      (result, [key, value]) => {
        if (
          ['aggregator_id', 'product_id', 'click_id'].includes(key)
            || key.startsWith('utm_')
        ) {
          return { ...result, [key]: value };
        }

        return result;
      },
      { product_id: 'credit' },
    ),
  );
  const aggregatorCookie = new CookieAggregator(name, options);
  aggregatorCookie.setRecord(params);
};
