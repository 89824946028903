export const MAGNET = {
  CORE: {
    PAGE_GTM_CATEGORY: 'credits_magnit_landing',
    PAGE_GTM_CLICK: 'credits_magnit_landing_click',
  },
  META: {
    TITLE: 'Получите 4 000 бонусов Магнита за оформление первого кредита',
    CANONICAL: '{{PUBLIC_HOST}}/potrebitelskie_kredity/landing/magnit',
    ADDITIONAL_LINKS: [],
    ADDITIONAL_METAS: [
      {
        property: 'og:site-name',
        content:
          'Финуслуги | Получите 4 000 бонусов Магнита за оформление первого кредита',
      },
      {
        property: 'og:description',
        content:
          'Финуслуги - маркетплейс финансовых услуг от Мосбиржи, платформа №1 в реестре ЦБ РФ.',
      },
      { property: 'og:type', content: 'website' },
    ],
  },
  HERO: {
    ORDER: 1,
    LINK: 'https://magnit.ru/upload/%D0%9F%D1%80%D0%B0%D0%B2%D0%B8%D0%BB%D0%B0%20%D0%B0%D0%BA%D1%86%D0%B8%D0%B8%20%D0%A4%D0%B8%D0%BD%D1%83%D1%81%D0%BB%D1%83%D0%B3%D0%B8%20%D0%BA%D1%80%D0%B5%D0%B4%D0%B8%D1%82%D1%8B.pdf',
    LINK_TARGET: '_blank',
    TEXTS: {
      subtitle: 'magnet.hero.subtitle',
      text: 'magnet.hero.text',
      link: 'magnet.hero.link',
      button: 'magnet.hero.button',
    },
    GTM: {
      clickLink: 'magnit_hero_link',
      clickButton: 'magnit_hero_button',
    },
  },
  PRODUCTS: {
    ORDER: 2,
    PLATFORM: {
      ID: 24,
      URL_PARAMS: {
        utm_source: 'magnit',
        utm_medium: 'affiliate',
        utm_campaign: 'finuslugi_aff_magnit_platform_kredity_mar-22',
      },
    },
    NON_PLATFORM: {
      ID: 25,
      URL_PARAMS: {
        utm_source: 'magnit',
        utm_medium: 'affiliate',
        utm_campaign: 'finuslugi_aff_magnit_neplatform_kredity_mar-22',
      },
    },
    TEXTS: {},
    GTM: {
      clickButton: 'magnit_products_item',
    },
  },
  PROCEED: {
    ORDER: 3,
    TEXTS: {
      title: 'magnet.proceed.title',
    },
    ITEMS: [
      {
        id: 1,
        text: 'Зарегистрируйтесь на Финуслугах, заполните кредитную заявку и введите номер карты Магнита в поле для промокода',
      },
      {
        id: 2,
        text: 'Выберите предложения от банков и отправьте заявку',
      },
      {
        id: 3,
        text: 'Дождитесь одобрения заявки и получите кредит на Финуслугах',
      },
      {
        id: 4,
        text: '4000 бонусов Магнит придут на карту лояльности Магнита в течение 10 дней после окончания месяца, когда был взят кредит',
      },
    ],
  },
  ABOUT: {
    ORDER: 4,
    GTM: {
      clickButton: 'magnit_about_button',
    },
  },
  USEFULL: {
    ORDER: 5,
    ITEMS: [
      {
        id: 1,
        name: 'Индекс доходности вкладов 20.02–27.02: ставки по краткосрочным вкладам немного снижаются, а по долгосрочным — растут',
        image: '/potrebitelskie_kredity/magnet/magnet-usefull_1.png',
        link: 'https://finuslugi.ru/vklady/stat_indeks_dohodnosti_vkladov_20_02_27_02_stavki_po_kratkosrochnym_vkladam_nemnogo_snizhayutsya_a_po_dolgosrochnym_rastut',
        rowType: 2,
        colType: 1,
      },
      {
        id: 2,
        name: 'Самозапрет на кредит: что это и как он работает',
        icon: '/potrebitelskie_kredity/magnet/magnet-usefull_chart-icon.svg',
        link: 'https://finuslugi.ru/potrebitelskie_kredity/stat_samozapret_na_kredit_chto_ehto_i_kak_on_rabotaet',
        rowType: 1,
        colType: 2,
      },
      {
        id: 3,
        name: 'Что такое акции',
        image: '/potrebitelskie_kredity/magnet/magnet-usefull_2.png',
        link: 'https://finuslugi.ru/navigator/stat_chto_takoe_akcii',
        rowType: 2,
        colType: 3,
      },
      {
        id: 4,
        name: 'Читать все<br />статьи журнала',
        icon: '/potrebitelskie_kredity/magnet/magnet-usefull_arrow-icon.svg',
        link: 'https://finuslugi.ru/navigator',
        background: 'dark-blue',
        rowType: 4,
        colType: 1,
      },
      {
        id: 5,
        name: 'Бренды ушли, а машины остались. Гайд по обслуживанию и ремонту (и новым брендам)',
        image: '/potrebitelskie_kredity/magnet/magnet-usefull_3.png',
        link: 'https://finuslugi.ru/navigator/stat_brendy_ushli_a_mashiny_ostalis_gajd_po_obsluzhivaniyu_i_remontu_i_novym_brendam',
        rowType: 3,
        colType: 2,
      },
      {
        id: 6,
        name: 'Как уйти на пенсию в 35–40 лет',
        icon: '/potrebitelskie_kredity/magnet/magnet-usefull_list-icon.svg',
        link: 'https://finuslugi.ru/navigator/nakopit-i-sohranit/stat_kak_ujti_na_pensiyu_v_35_40_let',
        rowType: 4,
        colType: 3,
      },
    ],
    GTM: {
      linkClick: 'magnit_usefull_link',
      clickButton: 'magnit_usefull_button',
    },
  },
  SUPPORT: {
    ORDER: 6,
    GTM: {
      phoneClick: 'magnit_support_phone',
      socialClick: 'magnit_support_social',
    },
  },
  FAQ: {
    ORDER: 7,
    ITEMS: [
      {
        id: 1,
        question:
          'Вклады, кредиты, страховки — онлайн на одной платформе или что такое Финуслуги?',
        answer:
          'Финуслуги — это проект Московской биржи. Банк России включил Финуслуги в реестр финансовых платформ под № 1. На Финуслугах можно выбирать и <a href="https://finuslugi.ru/vklady" target="_blank">открывать вклады</a>, <a href="https://osago.finuslugi.ru/?_ga=2.224549791.483919761.1677767040-118363526.1677677617" target="_blank">покупать страховки</a>, <a href="https://finuslugi.ru/invest/bonds" target="_blank">инвестировать</a>, <a href="https://finuslugi.ru/potrebitelskie_kredity" target="_blank">брать кредиты</a> в разных банках и страховых компаниях и управлять своими продуктами 24/7 онлайн в одном личном кабинете. Все банки на платформе имеют лицензию Банка России, а выписки о сделках можно в любой момент получить на Госуслугах. Деньги на вкладах застрахованы — в каждом банке до 1,4 млн рублей.',
      },
      {
        id: 2,
        question: 'Чем Финуслуги отличаются от маркетплейсов?',
        answer: `В отличие от сайтов-агрегаторов, где можно только посмотреть предложения, а оформлять заявку нужно уже в выбранном банке, на Финуслугах можно не просто сравнить и выбрать оптимальное предложение от банка или страховой, но и тут же все оформить онлайн — не нужно регистрироваться в разных банках, скачивать разные мобильные приложения. Управлять всеми продуктами можно в одном личном кабинете. На платформе вы сразу видите полную информацию о продукте — на Финуслугах нет никаких допусловий и «звездочек». Также пользователи Финуслуг получают:  
          * Эксклюзивные предложения — более выгодные ставки на вклады, чем в самих банках;  
          * Уникальные продукты — можно инвестировать в народные облигации Калининграда.`,
      },
      {
        id: 3,
        question: 'Как стать клиентом?',
        answer:
          'Чтобы начать пользоваться платформой, нужно авторизоваться через подтвержденный профиль с Госуслуг. После этого необходимо один раз встретиться с представителем платформы, чтобы подтвердить личность. По закону это нужно, чтобы мошенники не смогли совершать финансовые операции от вашего лица. Такая двухэтапная регистрация поможет защитить ваши финансы. Чтобы пройти идентификацию, в личном кабинете нажмите кнопку «Запланировать встречу» и выберите удобное место и время. Встреча не займет больше 10-15 минут. А дальше все просто — можно пользоваться всеми продуктами на платформе и управлять ими из одного личного кабинета. Пополнение счета — через систему быстрых платежей и по QR-кодам.',
      },
    ],
    TEXTS: {
      title: 'magnet.faq.title',
    },
    GTM: {
      linkClick: 'magnit_faq_link',
    },
  },
  FOOTER: {
    TEXTS: {
      caption: 'magnet.footer.caption',
    },
    GTM: {
      clickPhone: 'magnit_footer_phone',
    },
  },
};
