/* eslint-disable no-shadow, no-param-reassign */
import { formatPhoneNumber } from '@finuslugi/common-utils';

const TIME_ZONE_TEXT_SHORT_MOSCOW = 'мск';

const noWrap = (phone) => `<span class="no-wrap"> ${formatPhoneNumber(phone)}</span>`;

const state = () => ({
  common: {},
});

const getters = {
  supportEmail: ({ common }) => common?.SUPPORT_EMAIL || 'service@finuslugi.ru',
  supportPhone: ({ common }) => common?.SUPPORT_PHONE || '8 800 505-32-32',
  supportPhoneAlt: ({ common }) => common?.SUPPORT_PHONE_ALT || '+7 495 145-32-32',
  supportWorkTimeFrom: ({ common }) => common?.SUPPORT_WORKTIME_FROM || '7:00',
  supportWorkTimeTill: ({ common }) => common?.SUPPORT_WORKTIME_TILL || '23:00',
  supportWorkTime: (_, { supportWorkTimeFrom, supportWorkTimeTill }) => (
    `с ${supportWorkTimeFrom} до ${supportWorkTimeTill} ${TIME_ZONE_TEXT_SHORT_MOSCOW}`
  ),
  aggregatorById: ({ aggregators }) => (id) => (
    (id && typeof id === 'string' && aggregators[id.toLowerCase()]) || null
  ),
  supportCallSubstring: (_, {
    supportPhone, supportPhoneAlt, supportWorkTime,
  }) => [
    'обратитесь в нашу службу поддержки по телефонам',
    noWrap(supportPhoneAlt),
    'или',
    noWrap(supportPhone),
    `${supportWorkTime}.`,
  ].join(' '),
  supportCallText(_, { supportCallSubstring }) {
    return `Пожалуйста, ${supportCallSubstring}`;
  },
  diaPhone: ({ common }) => formatPhoneNumber(common?.DIA_PHONE || '8 800 200-08-05'),
};

const mutations = {
  set(state, common) {
    state.common = common;
  },
};

const actions = {
  async fetch({ commit }) {
    const { data } = await this.$axios(`${this.$config.PL_LK_API}/api/v1/dictionary/common`);

    commit('set', data);
  },
};

const dictionary = {
  actions,
  state,
  mutations,
  getters,
};

export { dictionary as default };
