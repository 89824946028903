// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".o18ShKgmIfBFK4aPML4s0g\\=\\= ul{padding-left:24px;list-style-type:\"— \"}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"marked": "o18ShKgmIfBFK4aPML4s0g=="
};
module.exports = ___CSS_LOADER_EXPORT___;
