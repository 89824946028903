import Cookie from 'js-cookie';
import merge from 'lodash/merge';
import isAfter from 'date-fns/isAfter';
import addDays from 'date-fns/addDays';

export class CookieAggregator {
  CookieManager = Cookie;

  static map = {
    in(record) {
      return Object.entries(record).reduce((result, [key, value]) => {
        const properKey = key.split('_').map((item, index) => {
          if (index > 0) {
            return `${item.charAt(0).toUpperCase()}${item.slice(1)}`;
          }

          return item;
        }).join('');

        return { ...result, [properKey]: value, raw: record };
      }, {});
    },
    out(record) {
      return Object.entries(record).reduce((result, [key, value]) => {
        const properKey = key.replace(/[A-Z]/, (char) => `_${char.toLowerCase()}`);

        return { ...result, [properKey]: value, prettify: record };
      }, {});
    },
  };

  static get domain() {
    if (process.env.NODE_ENV !== 'production') {
      return 'localhost';
    }

    if (window && window?.location) {
      return `.${window.location.hostname.split('.').slice(-2).join('.')}`;
    }

    return '';
  }

  static get secure() {
    if (process.env.NODE_ENV !== 'production') {
      return false;
    }

    if (window && window?.location) {
      return window.location.protocol.includes('https');
    }

    return '';
  }

  static get defaultOptions() {
    return {
      lifetime: 30,
      handlingTypes: ['credit', 'credit_card'],
    };
  }

  constructor(name, options) {
    this.$name = name;
    this.$options = merge(CookieAggregator.defaultOptions, options);
  }

  get $cookieParams() {
    return {
      domain: CookieAggregator.domain,
      path: '/',
      expires: this.$options.lifetime,
      secure: CookieAggregator.secure,
    };
  }

  get params() {
    try {
      return JSON.parse(this.CookieManager.get(this.$name, this.$cookieParams))
        .payload
        .map(CookieAggregator.map.in);
    } catch (_err) {
      return [];
    }
  }

  $cleanParamsList() {
    const { lifetime, handlingTypes } = this.$options;
    const data = this.params.filter(({ productId, createDate, endDate }) => {
      return !handlingTypes.includes(productId)
        || (endDate && !isAfter(new Date(), new Date(endDate)))
        || (!endDate && !isAfter(new Date(), addDays(new Date(createDate), lifetime)));
    });
    this.CookieManager.set(
      this.$name,
      { payload: data.map(({ raw }) => raw) },
      this.$cookieParams,
    );
  }

  getLastRecord(type) {
    this.$cleanParamsList();
    const { handlingTypes } = this.$options;
    const record = this.params.filter(({ productId }) => {
      return handlingTypes.includes(productId) && productId === type;
    })
      .sort((a, b) => {
        return new Date(a.createDate) - new Date(b.createDate);
      })
      .pop();

    if (!record) {
      return record;
    }

    return {
      ...record,
      remove: () => {
        const data = this.params.filter(({ productId }) => {
          return handlingTypes.includes(productId) && productId !== type;
        });

        this.CookieManager.set(
          this.$name,
          { payload: data.map(({ raw }) => raw) },
          this.$cookieParams,
        );
      },
    };
  }

  setRecord(payload) {
    const record = { createDate: new Date().toISOString(), ...payload };
    const { prettify: _, ...raw } = CookieAggregator.map.out(record);
    record.raw = raw;
    const data = this.getLastRecord(record.productId)
      ? this.params.map((item) => (item.productId === record.productId ? record : item))
      : [...this.params, record];

    this.CookieManager.set(
      this.$name,
      { payload: data.map((item) => item.raw) },
      this.$cookieParams,
    );
  }
}
