export const MAGNET2 = {
  CORE: {
    PAGE_GTM_CATEGORY: 'credits_magnit2_landing',
    PAGE_GTM_CLICK: 'credits_magnit2_landing_click',
  },
  META: {
    TITLE: 'Получите 4 000 бонусов Магнита за оформление первого кредита',
    CANONICAL: '{{PUBLIC_HOST}}/potrebitelskie_kredity/landing/magnit2',
    ADDITIONAL_LINKS: [],
    ADDITIONAL_METAS: [
      {
        property: 'og:site-name',
        content:
          'Финуслуги | Получите 4 000 бонусов Магнита за оформление первого кредита',
      },
      {
        property: 'og:description',
        content:
          'Финуслуги - маркетплейс финансовых услуг от Мосбиржи, платформа №1 в реестре ЦБ РФ.',
      },
      { property: 'og:type', content: 'website' },
    ],
  },
  HERO: {
    ORDER: 1,
    IMAGE_SRC: '/potrebitelskie_kredity/magnet2/magnet2-hero.svg',
    IMAGE_ALT: 'телефон в руках',
    BUTTON: {
      URL: null,
      TARGET: '_self',
      NAVIGATE: '#products',
    },
    LINK: {
      URL: 'https://magnit.ru/upload/%D0%9F%D1%80%D0%B0%D0%B2%D0%B8%D0%BB%D0%B0%20%D0%B0%D0%BA%D1%86%D0%B8%D0%B8%20%D0%A4%D0%B8%D0%BD%D1%83%D1%81%D0%BB%D1%83%D0%B3%D0%B8%20%D0%BA%D1%80%D0%B5%D0%B4%D0%B8%D1%82%D1%8B.pdf',
      TARGET: '_blank',
    },
    TEXTS: {
      title: 'magnet2.hero.title',
      caption: 'magnet2.hero.caption',
      button: 'magnet2.hero.button',
      link: 'magnet2.hero.link',
    },
    GTM: {
      clickLink: 'magnit2_hero_link',
      clickButton: 'magnit2_hero_button',
    },
  },
  PRODUCTS: {
    ORDER: 2,
    TABS: [
      {
        id: 1,
        name: 'Онлайн на Финуслугах',
        shortName: 'Онлайн',
        value: 'online',
        configKey: 'PLATFORM',
      },
      {
        id: 2,
        name: 'В отделении банка',
        shortName: 'В отделении',
        value: 'offline',
        configKey: 'NON_PLATFORM',
      },
    ],
    PLATFORM: {
      ID: 24,
      COUNT: 4,
      URL_TEMPLATE:
        'https://lk.finuslugi.ru/credits/new?productId={{entity.productId}}&skipName&silent&registrationMode=gosuslugi&utm_source={{entity.utm_source}}&utm_medium={{entity.utm_medium}}&utm_campaign={{entity.utm_campaign}}',
      URL_TARGET: '_blank',
      URL_PARAMS: {
        utm_source: 'magnit2',
        utm_medium: 'affiliate',
        utm_campaign: 'finuslugi_aff_magnit_platform-2_kredity_apr-11',
      },
      TEXTS: {
        itemButton: 'landing.products.item.button',
      },
      GTM: {
        clickButton: 'magnit2_products_item',
      },
    },
    NON_PLATFORM: {
      ID: 25,
      COUNT: 4,
      URL_TEMPLATE:
        'https://lk.finuslugi.ru/credits/new?productId={{entity.productId}}&skipName&silent&registrationMode=gosuslugi&utm_source={{entity.utm_source}}&utm_medium={{entity.utm_medium}}&utm_campaign={{entity.utm_campaign}}',
      URL_TARGET: '_blank',
      URL_PARAMS: {
        utm_source: 'magnit2',
        utm_medium: 'affiliate',
        utm_campaign: 'finuslugi_aff_magnit_neplatform-2_kredity_apr-11',
      },
      TEXTS: {
        itemButton: 'landing.products.item.button-nonplatform',
      },
      GTM: {
        clickButton: 'magnit2_products_item',
      },
    },
    TEXTS: {
      title: 'magnet2.products.title',
    },
    GTM: {
      tabChange: 'magnit2_products_tab',
    },
  },
  STEPS: {
    ORDER: 3,
    PLATFORM: [
      {
        id: 1,
        // image: '/potrebitelskie_kredity/magnet2/magnet2-step1.png',
        // title: 'magnet2.steps.item.1.title',
        text: 'magnet2.steps.item.platform-1.text',
      },
      {
        id: 2,
        // image: '/potrebitelskie_kredity/magnet2/magnet2-step2.png',
        // title: 'magnet2.steps.item.2.title',
        text: 'magnet2.steps.item.platform-2.text',
      },
      {
        id: 3,
        // image: '/potrebitelskie_kredity/magnet2/magnet2-step3.png',
        // title: 'magnet2.steps.item.3.title',
        text: 'magnet2.steps.item.platform-3.text',
      },
      {
        id: 4,
        text: 'magnet2.steps.item.platform-4.text',
      },
    ],
    NON_PLATFORM: [
      {
        id: 1,
        // image: "/potrebitelskie_kredity/magnet2/magnet2-step1.png",
        // title: "magnet2.steps.item.1.title",
        text: 'magnet2.steps.item.neplatform-1.text',
      },
      {
        id: 2,
        // image: "/potrebitelskie_kredity/magnet2/magnet2-step4.png",
        // title: "magnet2.steps.item.4.title",
        text: 'magnet2.steps.item.neplatform-2.text',
      },
      {
        id: 3,
        // image: '/potrebitelskie_kredity/magnet2/magnet2-step3.png',
        // title: 'magnet2.steps.item.3.title',
        text: 'magnet2.steps.item.neplatform-3.text',
      },
      {
        id: 4,
        text: 'magnet2.steps.item.neplatform-4.text',
      },
      {
        id: 5,
        text: 'magnet2.steps.item.neplatform-5.text',
      },
    ],
    BUTTON: {
      URL: null,
      TARGET: '_self',
      NAVIGATE: '#products',
    },
    TEXTS: {
      title: 'magnet2.steps.title',
      button: 'magnet2.steps.button',
      badge: 'magnet2.steps.badge',
    },
    GTM: {
      clickButton: 'magnit2_steps_button',
    },
  },
  ADVANTAGES: {
    ORDER: 4,
    ITEMS: [
      {
        id: 1,
        image: '/potrebitelskie_kredity/magnet2/magnet2-advantages__star.svg',
        title: 'magnet2.advantages.item.1.title',
        text: 'magnet2.advantages.item.1.text',
        color: 'rgba(251, 150, 57, 0.24)',
      },
      {
        id: 2,
        image: '/potrebitelskie_kredity/magnet2/magnet2-advantages__shield.svg',
        title: 'magnet2.advantages.item.2.title',
        text: 'magnet2.advantages.item.2.text',
        color: 'rgba(99, 177, 243, 0.24)',
      },
      {
        id: 3,
        image: '/potrebitelskie_kredity/magnet2/magnet2-advantages__hand.svg',
        title: 'magnet2.advantages.item.3.title',
        text: 'magnet2.advantages.item.3.text',
        color: 'rgba(73, 197, 93, 0.24)',
      },
    ],
    TEXTS: {
      title: 'magnet2.advantages.title',
    },
    GTM: {},
  },
  PARTNERS: {
    ORDER: 5,
    ITEMS: [
      {
        id: 1,
        name: 'Газпромбанк',
        bic: '044525823',
      },
      {
        id: 2,
        name: 'Тинькофф',
        bic: '044525974',
      },
      {
        id: 3,
        name: 'Банк Центр-Инвест',
        bic: '046015762',
      },
      {
        id: 5,
        name: 'СинараБанк',
        bic: '046577756',
      },
      {
        id: 6,
        name: 'Совкомбанк',
        bic: '043469743',
      },
      {
        id: 8,
        name: 'Металлинвестбанк',
        bic: '044525176',
      },
    ],
    TEXTS: {
      title: 'magnet2.partners.title',
    },
  },
  SUPPORT: {
    ORDER: 6,
    GTM: {
      phoneClick: 'magnit_support_phone',
      socialClick: 'magnit_support_social',
    },
  },
  FAQ: {
    ORDER: 7,
    ITEMS: [
      {
        id: 1,
        question:
          'Вклады, кредиты, страховки — онлайн на одной платформе или что такое Финуслуги?',
        answer:
          'Финуслуги — это проект Московской биржи. Банк России включил Финуслуги в реестр финансовых платформ под № 1. На Финуслугах можно выбирать и <a href="https://finuslugi.ru/vklady" target="_blank">открывать вклады</a>, <a href="https://osago.finuslugi.ru/?_ga=2.224549791.483919761.1677767040-118363526.1677677617" target="_blank">покупать страховки</a>, <a href="https://finuslugi.ru/invest/bonds" target="_blank">инвестировать</a>, <a href="https://finuslugi.ru/potrebitelskie_kredity" target="_blank">брать кредиты</a> в разных банках и страховых компаниях и управлять своими продуктами 24/7 онлайн в одном личном кабинете. Все банки на платформе имеют лицензию Банка России, а выписки о сделках можно в любой момент получить на Госуслугах. Деньги на вкладах застрахованы — в каждом банке до 1,4 млн рублей.',
      },
      {
        id: 2,
        question: 'Чем Финуслуги отличаются от маркетплейсов?',
        answer: `В отличие от сайтов-агрегаторов, где можно только посмотреть предложения, а оформлять заявку нужно уже в выбранном банке, на Финуслугах можно не просто сравнить и выбрать оптимальное предложение от банка или страховой, но и тут же все оформить онлайн — не нужно регистрироваться в разных банках, скачивать разные мобильные приложения. Управлять всеми продуктами можно в одном личном кабинете. На платформе вы сразу видите полную информацию о продукте — на Финуслугах нет никаких допусловий и «звездочек». Также пользователи Финуслуг получают:  
          * Эксклюзивные предложения — более выгодные ставки на вклады, чем в самих банках;  
          * Уникальные продукты — можно инвестировать в народные облигации Калининграда.`,
      },
      {
        id: 3,
        question: 'Как стать клиентом?',
        answer:
          'Чтобы начать пользоваться платформой, нужно авторизоваться через подтвержденный профиль с Госуслуг. После этого необходимо один раз встретиться с представителем платформы, чтобы подтвердить личность. По закону это нужно, чтобы мошенники не смогли совершать финансовые операции от вашего лица. Такая двухэтапная регистрация поможет защитить ваши финансы. Чтобы пройти идентификацию, в личном кабинете нажмите кнопку «Запланировать встречу» и выберите удобное место и время. Встреча не займет больше 10-15 минут. А дальше все просто — можно пользоваться всеми продуктами на платформе и управлять ими из одного личного кабинета. Пополнение счета — через систему быстрых платежей и по QR-кодам.',
      },
    ],
    TEXTS: {
      title: 'magnet.faq.title',
    },
    GTM: {
      linkClick: 'magnit_faq_link',
    },
  },
  FOOTER: {
    TEXTS: {
      caption: 'magnet.footer.caption',
    },
    GTM: {
      clickPhone: 'magnit_footer_phone',
    },
  },
};
