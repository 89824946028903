export const REGISTRATION = {
  title: 'Финуслуги — первая платформа личных финансов',
  canonical: '{{PUBLIC_HOST}}/potrebitelskie_kredity/promo/registration/',
  meta: [
    { name: 'description', content: 'Финуслуги — первая платформа личных финансов. Оформляйте кредиты без визита в банк. Это удобно и безопасно.' },
    { property: 'og:title', content: 'Финуслуги — первая платформа личных финансов' },
    { property: 'og:description', content: 'Финуслуги — первая платформа личных финансов. Оформляйте кредиты без визита в банк. Это удобно и безопасно.' },
    { property: 'og:image', content: '{{PUBLIC_HOST}}/icons/1200x630-logo.jpg' },
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: '{{PUBLIC_HOST}}/potrebitelskie_kredity/promo/registration/' },
    { property: 'og:site_name', content: 'Финуслуги' },
  ],
};
