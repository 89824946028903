export const TELE2 = {
  CORE: {
    PAGE_GTM_CATEGORY: 'credits_tele2_landing',
    PAGE_GTM_CLICK: 'credits_tele2_landing_click',
  },
  META: {
    TITLE:
      'Только для абонентов Tele2: вернем 3 000 рублей за кредит на Финуслугах',
    CANONICAL: '{{PUBLIC_HOST}}/potrebitelskie_kredity/landing/tele2',
    ADDITIONAL_LINKS: [],
    ADDITIONAL_METAS: [
      {
        property: 'og:site-name',
        content:
          'Финуслуги | Только для абонентов Tele2: вернем 3 000 рублей за кредит на Финуслугах',
      },
      {
        property: 'og:description',
        content:
          'Финуслуги - маркетплейс финансовых услуг от Мосбиржи, платформа №1 в реестре ЦБ РФ.',
      },
      { property: 'og:type', content: 'website' },
    ],
  },
  HERO: {
    ORDER: 1,
    LINK: 'https://assets.finuslugi.ru/mp-assets/docs/bonus-program-rules/pravila-aktsii-perviu-potreb-kredit-cherez-tele2/pravila-aktsii-perviu-potreb-kredit-cherez-tele2.pdf',
    LINK_TARGET: '_blank',
    TEXTS: {
      subtitle: 'tele2.hero.subtitle',
      text1: 'tele2.hero.text-1',
      text2: 'tele2.hero.text-2',
      promocode: 'tele2.hero.promo',
      link: 'tele2.hero.link',
      button: 'tele2.hero.button',
      copy: 'tele2.hero.copy',
    },
    GTM: {
      clickLink: 'tele2_hero_link',
      clickButton: 'tele2_hero_button',
    },
  },
  PRODUCTS: {
    ORDER: 2,
    PLATFORM: {
      ID: 26,
      URL_PARAMS: {
        utm_source: 'tele2',
        utm_medium: 'affiliate',
        utm_campaign: 'finuslugi_aff_tele2_platform_kredity_mar-22',
      },
    },
    NON_PLATFORM: {
      ID: 27,
      URL_PARAMS: {
        utm_source: 'tele2',
        utm_medium: 'affiliate',
        utm_campaign: 'finuslugi_aff_tele2_neplatform_kredity_mar-22',
      },
    },
    TEXTS: {
      platformTitle: 'tele2.products.platform.title',
      nonPlatformTitle: 'tele2.products.non-platform.title',
    },
    GTM: {
      clickButton: 'tele2_products_item',
    },
  },
  PROCEED: {
    ORDER: 3,
    TEXTS: {
      title: 'tele2.proceed.title',
    },
    ITEMS: [
      {
        id: 1,
        text: 'Зарегистрируйтесь на Финуслугах, привяжите Госуслуги, заполните кредитную заявку и введите <strong>промокод TELE23</strong>',
      },
      {
        id: 2,
        text: 'Выберите предложения от банков, отправьте заявку и получите одобрение кредита',
      },
      {
        id: 3,
        text: 'Дождитесь одобрения заявки и получите кредит на Финуслугах',
      },
      {
        id: 4,
        text: '2000 рублей придут на счет на Финуслугах, а еще 1000 рублей — на ваш номер телефона Tele2',
      },
    ],
  },
  ABOUT: {
    ORDER: 4,
    IMAGE: '/potrebitelskie_kredity/tele2/tele2-about__image.svg',
    ALT: 'Город',
    GTM: {
      clickButton: 'tele2_about_button',
    },
  },
  SUPPORT: {
    ORDER: 6,
    GTM: {
      phoneClick: 'tele2_support_phone',
      socialClick: 'tele2_support_social',
    },
  },
  FAQ: {
    ITEMS: [
      {
        id: 1,
        question: 'Какой бонус от Tele2 я получу? Когда мне его начислят?',
        answer:
          '1000 рублей от Tele2 поступят на счет вашего номера мобильного телефона в течение 90 рабочих дней после оформления кредита. Вы сможете потратить их на услуги связи Tele2',
      },
      {
        id: 2,
        question:
          'Когда я получу бонус от Финуслуг? Как я узнаю, что деньги пришли?',
        answer:
          'Вы получите 2000 рублей от Финуслуг в течение 60 рабочих дней после оформления кредита. Деньги придут на Кошелек. Это ваш личный счет на Финуслугах, он виден в личном кабинете на сайте или в мобильном приложении. Вывести деньги с Кошелька можно на свой счет в любом российском банке РФ — например, по номеру карты или по реквизитам. Без комиссии.',
      },
      {
        id: 3,
        question:
          'Бонус можно получить за любой кредит на Финуслугах или только за первый?',
        answer:
          'Только за первый. Если у вас есть или был кредит, который вы оформляли на Финуслугах, мы не сможем начислить вам бонусы.',
      },
      {
        id: 4,
        question: 'Кредит важно оформить онлайн?',
        answer:
          'Не обязательно. Вы можете оформить кредит полностью онлайн или заполнить заявку на Финуслугах, а кредит получить уже в отделении банка. Важно оформить кредитную заявку именно на сайте или в приложении Финуслуг — тогда мы начислим вам бонусы.',
      },
    ],
    GTM: {
      linkClick: 'tele2_faq_link',
    },
  },
  FOOTER: {
    TEXTS: {
      caption: 'tele2.footer.caption.markdown',
    },
    GTM: {
      clickPhone: 'tele2_footer_phone',
    },
  },
};
