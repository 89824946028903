/* istanbul ignore file */
import { NodeType } from '@/utils/constants/NodeType';

export const isOutsideClick = (rootElem, event) => {
  if (!event.isTrusted) {
    return false;
  }

  const isElementNode = (item) => item.nodeType && item.nodeType !== NodeType.DocumentNode;
  return Array.from(event.composedPath()).every((item) => (isElementNode(item)
  ? !item.isEqualNode(rootElem)
  : true));
};
